import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import {
  Button,
  CloseButton,
  Card,
  Col,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";

import {
  SelectFilter,
  MultiSelectID,
  NumberRangeFilter,
} from "components/filters/filters";

import { FilterSortDLTable as Table } from "components/table/tables";

import ActionButton from "components/common/ActionButton";
import TagRecordForm from "components/forms/TagRecordForm";
import { useAPI2 } from "hooks/useFetch";

const TagSetup = () => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: "Site",
        accessor: "site.name",
      },
      {
        Header: "Machine",
        accessor: "machine.name",
      },
      {
        Header: "Tag Number",
        accessor: "tagNumber",
        Filter: NumberRangeFilter,
        filter: "between",
      },
      {
        Header: "Part",
        accessor: "servicePart",
      },
      {
        Header: "Task",
        accessor: "serviceTask",
      }, 
      {
        Header: "Active",
        accessor: (d) => d.active.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Edit",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Edit"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowItemModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
      {
        Header: "Archive",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Archive"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowArchiveModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, loading, error, fetchTags] = useAPI2();
  const [, , , archiveItem] = useAPI2();

  const allTags = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(true);

  // Edit Item
  const [item, setItem] = useState(null);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  // Prefilters
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);

  // Filtered Table Data
  const [filteredRows, setFilteredRows] = useState([]);

  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      sites: selectedSites.map((s) => s.value),
      machines: selectedMachines.map((m) => m.value),
    });

    if (update) {
      fetchTags({
        path: `/tags?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Data after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleArchive = async (id) => {
    const data = { _id: id, archived: true };
    archiveItem({
      path: "/tag/archive/id/" + id,
      method: "PATCH",
      payload: data,
    });
    setUpdate(true);
  };

  function ItemModal() {
    return (
      <>
        <Modal
          show={showItemModal}
          onHide={() => {
            setUpdate(true);
            setShowItemModal(false);
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                {item ? "Edit Record" : "Add Record"}
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowItemModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-4">
            <TagRecordForm
              selectedSites={selectedSites}
              selectedMachines={selectedMachines}
              filteredRows={filteredRows}
              tag={item}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setUpdate(true);
                setShowItemModal(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function ArchiveItemModal() {
    return (
      <>
        <Modal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Archive Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowArchiveModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Are you sure you want to archive the record for tag{" "}
              {item.tagNumber}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleArchive(item._id);
                setShowArchiveModal(false);
              }}
            >
              Archive
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowArchiveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {result.message && <Alert variant="success">{result.message}</Alert>}
      {loading && <Alert variant="warning">{"Loading data..."}</Alert>}
      {showItemModal && <ItemModal item={item} />}
      {showArchiveModal && <ArchiveItemModal item={item} />}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Tag Setup
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Table Filters</h5>
            <Col>
              <MultiSelectID
                data={allTags}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>
            <Col>
              <MultiSelectID
                data={allTags}
                column={"machine"}
                value={"_id"}
                label={"name"}
                selected={selectedMachines}
                setSelected={setSelectedMachines}
              />
            </Col>
            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Update Table
              </Button>
            </Col>
            <Col className="align-self-center">
              <div className="text-end">
                <Button
                  variant="primary"
                  onClick={() => {
                    setItem(null)
                    setShowItemModal(true);
                  }}
                >
                  Add Record
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Table
              columns={columns}
              data={allTags}
              setFilteredRows={setFilteredRows}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default TagSetup;
