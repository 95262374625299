import { useState, useCallback } from "react";
import { useHistory } from "react-router";
import { authHeader, userLogout } from "../context/Authorization";
import { API_URL } from "../App";

// export const useAPI = () => {
//   const [res, setRes] = useState({
//     data: [],
//     loading: false,
//     error: null,
//     message: null,
//   });

//   const callAPI = useCallback((params) => {
//     setRes((prevState) => ({ ...prevState, loading: true }));

//     const request = {
//       method: params.method,
//       headers: authHeader(),
//       ...(params.payload && { body: JSON.stringify(params.payload) }),
//     };

//     fetch(API_URL + params.path, request)
//       .then((response) => {
//         return response.json();
//       })
//       .then((json) => {
//         // console.log(json);
//         if (json.message === "Unauthorized!") throw json.message;

//         setRes({
//           ...res,
//           ...(params.method === "GET" && { data: json }),
//           loading: false,
//           error: null,
//         });
//       })
//       .catch((error) => {
//         setRes({ data: [], loading: false, error: error });
//       });
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   return [res, callAPI];
// };

export const useAPI2 = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const callAPI = useCallback((params) => {
    setData([]);
    setLoading(true);
    setError(error);

    const request = {
      method: params.method,
      headers: authHeader(),
      ...(params.payload && { body: JSON.stringify(params.payload) }),
    };

    fetch(API_URL + params.path, request)
      .then((response) =>
        response
          .json()
          .then((data) => ({
            json: data,
            status: response.status,
          }))
          .then(({ status, json }) => {
            if (json.message === "Unauthorized!") {
              console.log("Request by unauthorized user");
              userLogout();
              history.push("/login");
              throw new Error(json.message);
            }
            if (status < 200 || status > 299) {
              console.log(json);
              throw new Error(json.message);
            }
            if (params.method !== "DELETE") {
              // console.log(json)
              setData(json);
            }
            setLoading(false);
            setError(null);
          })
      )
      .catch((error) => {
        console.log(error);
        setData([]);
        setLoading(false);
        setError(error?.message || "Unable to connect to server");
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, loading, error, callAPI];
};

export const useFetchBlob = () => {
  const [blob, setBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBlob = useCallback((params) => {
    setBlob(null);
    setLoading(true);
    setError(error);

    const request = {
      method: params.method,
      headers: authHeader(),
      ...(params.payload && { body: JSON.stringify(params.payload) }),
    };

    fetch(API_URL + params.path, request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error, status = " + response.status);
        }

        let blob = response.blob()
        return blob
      })
      .then((blob) => {
        if (params.method !== "DELETE") {
          setBlob(blob);
        }
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log(error);
        setBlob([]);
        setLoading(false);
        setError(error?.message || "Unable to connect to server");
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { blob, loading, error, fetchBlob };
};
