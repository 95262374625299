import React from 'react';
import ProfileBanner from './Banner';


const Profile = () => {
  return (
    <>
      <ProfileBanner />
    </>
  );
};

export default Profile;
