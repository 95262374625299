import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import {
  Button,
  CloseButton,
  Card,
  Col,
  Row,
  Alert,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import ActionButton from "components/common/ActionButton";
import { getCurrentUser } from "context/Authorization";
import { useAPI2 } from "hooks/useFetch";
import {
  SelectFilter,
  DateRangeFilter,
  MultiSelect,
  MultiSelectID,
} from "components/filters/filters";

import { FilterSortSelectTable as Table } from "components/table/tables";

const WorkRecords = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = React.useMemo(
    () => [
      {
        Header: "Tech",
        accessor: "user.username",
      },
      {
        Header: "Site",
        accessor: "site.name",
      },
      {
        Header: "Ticket",
        accessor: "ticket.ticketName",
      },
      {
        Header: "Started",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Complete",
        accessor: (d) => d.complete.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Released",
        accessor: (d) => d.released.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Archive",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Archive"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowArchiveModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, , error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const [uResult, , uError, updateRecords] = useAPI2();
  const [aResult, , aError, archiveRecords] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(false);

  // Select & Release
  const [selected, setSelected] = useState([]);
  const [status, setStatus] = useState(null);

  // Prefilters
  // const [selectedUsers, setSelectedUsers] = useState([]);

  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  // const [selectedTickets, setSelectedTickets] = useState([]);

  // Item Modal
  const [item, setItem] = useState(null);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);

  // Verify user is authorized
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({});
    fetchSites({
      path: `/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map(s => {
      return {"site": s}
    })
    setSiteList(mysites);
  }, [sites]);

  // release records
  const handleUpdate = () => {
    updateRecords({
      path: `/workrecords/release`,
      method: "PATCH",
      payload: {
        ids: selected,
        status: status,
      },
    });
    setUpdate(true);
  };

  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      ...(user && { user: user.id }),
      // users: selectedUsers.map((u) => u.value),
      sites: selectedSites.map((s) => s.value),
      // tickets: selectedTickets.map((t) => t.value),
    });

    if (update) {
      callAPI({
        path: `/workrecords?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Data after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleArchive = async (id) => {
    const data = { _id: id, archived: true };
    archiveRecords({
      path: "/workrecord/archive/id/" + id,
      method: "PATCH",
      payload: data,
    });
    setUpdate(true);
  };

  function ArchiveItemModal() {
    return (
      <>
        <Modal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Archive Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowArchiveModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Are you sure you want to archive the record for {item.issueName}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleArchive(item._id);
                setShowArchiveModal(false);
              }}
            >
              Archive
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowArchiveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function ReleaseItemModal() {
    return (
      <>
        <Modal
          show={showReleaseModal}
          onHide={() => setShowReleaseModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                {`${status ? "Release" : "Restrict"} selected records`}
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowReleaseModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              {`Are you sure you want to ${
                status ? "release" : "restrict"
              } the selected records?`}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleUpdate();
                setShowReleaseModal(false);
              }}
            >
              Continue
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowReleaseModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {showArchiveModal && <ArchiveItemModal item={item} />}
      {showReleaseModal && <ReleaseItemModal />}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Review Work Records
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Select Site and Load Data</h5>
            {/* <Col>
              <MultiSelectID
                data={data}
                column={"user"}
                value={"_id"}
                label={"username"}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
              />
            </Col> */}

            <Col>
              <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>

            {/* <Col>
              <MultiSelectID
                data={data}
                column={"ticket"}
                value={"_id"}
                label={"ticketName"}
                selected={selectedTickets}
                setSelected={setSelectedTickets}
              />
            </Col> */}
            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Load Data
              </Button>
            </Col>
          </Row>

          <Row className="d-flex py-3 my-3 justify-content-center border rounded-3">
            {(user && user.type === "admin") ||
              (user.type === "superadmin" && (
                <Row className="mb-3 text-start">
                  <Col>
                    <Button
                      variant="outline-warning"
                      onClick={() => {
                        setStatus(false);
                        setShowReleaseModal(true);
                      }}
                    >
                      Restrict Selected Records
                    </Button>
                    <Button
                      className="ms-3"
                      variant="outline-success"
                      onClick={() => {
                        setStatus(true);
                        setShowReleaseModal(true);
                      }}
                    >
                      Release Selected Records
                    </Button>
                  </Col>
                </Row>
              ))}

            <Table columns={columns} data={data} updateSelected={setSelected} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default WorkRecords;
