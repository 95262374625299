import React from "react";
import { Page, Text, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import {  Row, Col } from "react-bootstrap";

// Create styles
const styles = StyleSheet.create({
  body: {
    backgroundColor: "#FFFFFF",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 5,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  subtitle_center: {
    fontSize: 18,
    textAlign: "center",
    margin: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  mb3: {
    marginBottom: 3,
  },
});


const Report = (item) => {

  const myItem = item?.item?.item;

  const date = new Date(myItem?.timestamp);
  let areas = [...new Set(myItem?.serviceRecords.map((r) => r.machineName))];
  let issues = myItem?.siteIssues.map(i => {
    return {location: i.machineName, condition: i.issueName}
  })

  let areaArray = []
  for (let i = 0; i < areas.length; i +=2 ) {
    areaArray.push([ areas[i], i+1<areas.length ? areas[i+1] : '' ])
  }

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          {myItem?.site.company.name}
        </Text>
        <Text style={styles.title}>Daily Shift Workplace Exam</Text>

        <div style={styles.row}>
          <Text style={styles.text}>Location: {myItem?.site.name}</Text>
          <Text style={styles.text}>Shift: 2</Text>
          <Text style={styles.text}>Date: {date.toDateString()}</Text>
        </div>

        <Text style={styles.author}>
          Competent Person: {myItem?.user.username}
        </Text>

        <Text style={styles.title}>Working Area(s) Examined</Text>
        {/* <Text style={styles.author}>
        Place your initials by any area you examined
      </Text> */}

        {/* Work Areas */}
        {areaArray &&
          areaArray.map((r, i) => {
            return (
              <div key={i} style={styles.row}>
                <div style={styles.col}>
                  <div style={styles.rowLeft}>
                    <Text style={styles.text}>{r[0]}</Text>
                  </div>
                </div>
                <div style={styles.col}>
                  <div style={styles.rowLeft}>
                    <Text style={styles.text}>{r[1]}</Text>
                  </div>
                </div>
              </div>
            );
          })}

        <Text style={styles.subtitle}> </Text>

        {/* Issues Table Header*/}
        <div style={styles.row}>
          <Text style={styles.subtitle}>Location</Text>
          <Text style={styles.subtitle_center}>
            Conditions needing corrected
          </Text>
          <Text style={styles.subtitle}>Date corrected</Text>
        </div>

        {/* Data Mapping */}

        {issues?.map((row, index) => {
          return (
            <div key={index} style={styles.row}>
              <div style={styles.col}>
                <Text style={styles.text}>{row.location}</Text>
              </div>
              <div style={styles.col}>
                <Text style={styles.text}>{row.condition}</Text>
              </div>
              <div style={styles.col}>
                <Text style={styles.text}>_____</Text>
              </div>
            </div>
          );
        })}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const MyPDF = (item) => {
  return (
    item &&
    <Row className="justify-content-between align-items-center text-center">
      <Col>
        <PDFViewer width={500} height={750}>
          <Report item={item} />
        </PDFViewer>
      </Col>
    </Row>
  );
};

export default MyPDF;
