import React, { useContext, useEffect } from "react";
import { useHistory } from 'react-router';
import { Route, Switch, useLocation } from "react-router-dom";
import NavbarTop from "components/navbar/top/NavbarTop";
import NavbarVertical from "components/navbar/vertical/NavbarVertical";
import AppContext from "context/Context";
import MainRoutes from "./MainRoutes";
import Footer from "components/footer/Footer";
import classNames from "classnames";
import Dashboard from "../components/pages/Homepage";

import { getCurrentUser } from "context/Authorization";

const MainLayout = () => {
  const history = useHistory();

  const { pathname } = useLocation();
  const isKanban = pathname.includes("kanban");

  const user = getCurrentUser();

  // Ensure user logged in
  useEffect(() => {
    if (!user) {
      console.log("no user logged in");
      history.push("/login");
    }
  });

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <NavbarVertical />
      )}
      <div className={classNames("content", { "pb-0": isKanban })}>
        <NavbarTop />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <MainRoutes />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
