import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import { Button, Card, Col, Row, Alert } from "react-bootstrap";
import ActionButton from "components/common/ActionButton";
import { getCurrentUser } from "context/Authorization";
import { useAPI2, useFetchBlob } from "hooks/useFetch";
import { DateRangeFilter, MultiSelectID } from "components/filters/filters";

import { FilterSortTable as Table } from "components/table/tables";
import fileDownload from "js-file-download";

const CustomerServiceTrips = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = React.useMemo(
    () => [
      {
        Header: "Site",
        accessor: "site.name",
      },
      {
        Header: "Ticket",
        accessor: "ticketName",
      },
      {
        Header: "Date",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toDateString()}</p>;
        },
      },
      {
        Header: "Records",
        Cell: (rowData) => {
          const record = rowData.row.original;
          return (
            <ActionButton
              icon="table"
              title="View Spreadsheet"
              variant="action"
              onClick={() => {
                setItem(record);
                fetchSheet(record);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, , error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const { blob, error: blobError, fetchBlob } = useFetchBlob();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(false);
  const [item, setItem] = useState(null);

  // Prefilters
  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({ user: user.id });
    fetchSites({
      path: `/customer/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map((s) => {
      return { site: s };
    });
    setSiteList(mysites);
  }, [sites]);

  // Fetch Records
  useEffect(() => {
    const filters = JSON.stringify({
      user: user.id,
      sites: selectedSites.map((s) => s.value),
    });

    if (update) {
      callAPI({
        path: `/customer/workrecords/?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Spreadsheet
  const fetchSheet = (record) => {
    // console.log(record)
    const filters = JSON.stringify({
      user: user.id,
      ticket: record._id,
    });

    fetchBlob({
      path: `/customer/excel/?filters=${filters}`,
      method: "GET",
    });
  };

  // Download Spreadsheet
  useEffect(() => {
    const _date = new Date(item?.timestamp || 0);
    const date = _date?.toISOString().substring(0, 10).replace(/-/gi, "");
    const ticket = item?.ticketName?.replace(
      /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/]/gi,
      ""
    );
    const filename = `${item?.site?.name} - ${ticket} - ${date}.xlsx`;

    if (blob && !blobError) {
      fileDownload(blob, filename);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob, blobError]);

  // Logout unauthorized users
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Service Trips
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Select Site and Load Data</h5>

            <Col>
              <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>

            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Load Data
              </Button>
            </Col>
          </Row>

          <Row className="d-flex py-3 my-3 justify-content-center border rounded-3">
            <Table columns={columns} data={data} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerServiceTrips;
