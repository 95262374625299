import { useState, useCallback } from "react";
import { getCurrentUser } from "context/Authorization";

import {
  dashboardRoutes,
  customerRoutes,
  reviewRoutes,
  setupRoutes,
  adminRoutes,
  archiveRoutes,
} from "routes/routes";

export const useRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const superadmin = "superadmin";
  const admin = "admin";
  const tech = "tech";
  const customer = "customer";

  const updateRoutes = useCallback(() => {
    const user = getCurrentUser();

    if (user?.type) {
      switch (user.type) {
        case superadmin:
          setRoutes([
            dashboardRoutes,
            customerRoutes,
            reviewRoutes,
            setupRoutes,
            adminRoutes,
            archiveRoutes,
          ]);
          break;
        case admin:
          setRoutes([
            dashboardRoutes,
            customerRoutes,
            reviewRoutes,
            setupRoutes,
          ]);
          break;
        case tech:
          setRoutes([dashboardRoutes, customerRoutes, reviewRoutes]);
          break;
        case customer:
          setRoutes([dashboardRoutes, customerRoutes]);
          break;
        default:
          setRoutes([dashboardRoutes, customerRoutes]);
      }
    }
  });

  return [routes, updateRoutes];
};