import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Select from "react-select";
import MultiSelectSort from "components/filters/selects";

import { Button, Form, Row, Col } from "react-bootstrap";
import { useAPI2 } from "hooks/useFetch";

const TicketRecordForm = ({ item, handleForm }) => {
  // State
  const [formData, setFormData] = useState({
    site: "",
    ticketName: "",
    type: "",
    allotedMinutes: 0,
    tags: [],
    active: true,
  });

  const [sites, , , callAPI] = useAPI2();
  const [machines, , , getMachines] = useAPI2();
  const [tags, , , getTags] = useAPI2();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);

  // Initialize Form
  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id,
        site: item.site._id,
        ticketName: item.ticketName,
        type: item.type,
        allotedMinutes: item.allotedMinutes,
        tags: item.tags,
        active: item.active,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize Tags
  useEffect(() => {
    if (tags.length && formData.tags.length) {
      const fullTags = formData.tags
        .map((t) => {
          return tags.find((f) => f._id === t);
        })
        .map((t) => {
          return {
            value: t?._id,
            label: `${t?.tagNumber} - ${t?.machine.name} ${t?.servicePart}`,
          };
        });
      setSelectedTags(fullTags);
    }
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Sites
  useEffect(() => {
    callAPI({
      path: `/sites`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update tags and machines when site is changed
  useEffect(() => {
    if (formData.site) {
      const filters = JSON.stringify({
        sites: formData.site,
      });

      getMachines({
        path: `/machines?filters=${filters}`,
        method: "GET",
      });

      getTags({
        path: `/tags/site/${formData.site}`,
        method: "GET",
      });
    }
  }, [formData.site]); // eslint-disable-line react-hooks/exhaustive-deps

  // Populate machines based on selected tags
  useEffect(() => {
    const selectedTagIds = selectedTags.map((s) => s.value);

    const selectedFullTags = tags.filter((t) =>
      selectedTagIds.includes(t._id)
    );

    const selectedMachineIds = [
      ...new Set(selectedFullTags.map((t) => t.machine._id)),
    ];

    const machineNamesIds = machines
      .filter((m) => selectedMachineIds.includes(m._id))
      .map((m) => {
        return { value: m._id, label: m.name };
      });

    setSelectedMachines(machineNamesIds);
  }, [selectedTags]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update tags based on machines
  const addTagsbyMachines = () => {
    // Get the list of machine Ids
    const _machines = selectedMachines.map((m) => m.value);

    // Find the tags for the list of machines
    const _tags = tags
      .filter((t) => _machines.includes(t.machine?._id))
      .map((t) => {
        return {
          value: t._id,
          label: `${t.tagNumber} - ${t.machine.name} ${t.servicePart}`,
        };
      });

    // Update the selected tags
    setSelectedTags(_tags);
    setFormData({
      ...formData,
      tags: _tags.map((e) => e.value),
    });
  };

  //  Multi Select Handlers
  const handleTags = (e) => {
    setSelectedTags(e);

    setFormData({
      ...formData,
      tags: e.map((e) => e.value),
    });
  };

  const handleMachines = (e) => {
    setSelectedMachines(e);
  };

  function MultiSelectMachines({ data, selected, setSelected }) {
    const options = (data && Array.isArray(data) ? data : []).map((m) => {
      return {
        value: m._id,
        label: `${m.name}`,
      };
    });

    return (
      <Select
        defaultValue={selected}
        isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={setSelected}
      />
    );
  }

  // Form Handlers
  const handleSubmit = (e) => {
    e.preventDefault();
    handleForm(formData);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Site</Form.Label>
            <Form.Select
              placeholder="Site"
              value={formData.site}
              name="site"
              onChange={handleFieldChange}
            >
              <option></option>
              {sites.map((e) => {
                return <option value={e._id}>{e.name}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Ticket Name</Form.Label>
            <Form.Control
              type="text"
              name="ticketName"
              onChange={handleFieldChange}
              value={formData.ticketName}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Control
              type="text"
              name="type"
              onChange={handleFieldChange}
              value={formData.type}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Alloted Time</Form.Label>
            <Form.Control
              type="number"
              name="allotedMinutes"
              onChange={handleFieldChange}
              value={formData.allotedMinutes}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2 mb-3">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Ticket Status</Form.Label>
            <Form.Check
              type="switch"
              checked={formData.active}
              name="active"
              label="Active"
              onChange={handleSwitch}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Machines</Form.Label>
            <MultiSelectMachines
              data={machines}
              selected={selectedMachines}
              setSelected={handleMachines}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Label>Add machine tags</Form.Label>
          <Button className="w-100" onClick={addTagsbyMachines}>
            Update Tags
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Tags</Form.Label>
            <MultiSelectSort
              data={tags}
              selected={selectedTags}
              setSelected={handleTags}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.site ||
            !formData.ticketName ||
            !formData.type ||
            !formData.allotedMinutes
          }
          onClick={handleSubmit}
        >
          {item ? "Update Record" : "Add Record"}
        </Button>
      </Form.Group>
    </Form>
  );
};

TicketRecordForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default TicketRecordForm;
