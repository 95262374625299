import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { Button, Card, Row, Col, Alert } from "react-bootstrap";
// import { CSVLink } from "react-csv";

import {
  DateRangeFilter,
  MultiSelect,
  MultiSelectID,
  row_builder,
} from "components/filters/filters";
import { FilterSortDLTable as Table } from "components/table/tables";
import { getCurrentUser } from "context/Authorization";
import { useAPI2 } from "hooks/useFetch";

const CustomerServiceRecords = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = useMemo(
    () => [
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Machine",
        accessor: "machineName",
      },
      {
        Header: "Part Serviced",
        accessor: "partServiced",
      },
      {
        Header: "Work Done",
        accessor: "workDone",
      },
      {
        Header: "Completion Date",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toDateString()}</p>;
        },
      },
    ],
    []
  );

  // Table Data
  const [result, , error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(false);

  // Prefilters
  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  // const [selectedMachines, setSelectedMachines] = useState([]);

  // Filtered Table Data
  const [filteredRows, setFilteredRows] = useState([]);
  const [CSV, setCSV] = useState([]);

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({ user: user.id, });
    fetchSites({
      path: `/customer/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map(s => {
      return { "site": s }
    })
    setSiteList(mysites);
  }, [sites]);

  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      user: user.id,
      sites: selectedSites.map((s) => s.value),
      // machines: selectedMachines.map((m) => m.value),
    });

    if (update) {
      callAPI({
        path: `/customer/servicerecords/?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Verify authorization after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  // Export Data
  useEffect(() => {
    const cols = [
      "siteName",
      "machineName",
      "partServiced",
      "workDone",
      "timestamp",
    ];
    const headers = ["Site", "Machine", "Part", "Task", "Completion Date"];
    const rows = filteredRows.map((r) => row_builder(cols, r));

    const csv = [headers, ...rows];

    setCSV(csv);
  }, [filteredRows]);

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Service Records
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Select Site and Load Data</h5>
            <Col>
            <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>
            {/* <Col>
              <MultiSelect
                data={data}
                column={"machineName"}
                selected={selectedMachines}
                setSelected={setSelectedMachines}
              />
            </Col> */}
            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Load Data
              </Button>
            </Col>
            <Col className="align-self-center text-end">
              {/* <CSVLink
                data={CSV}
                filename={"Service Records.csv"}
                className="btn btn-outline-primary"
              >
                Download Records
              </CSVLink> */}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Table
              columns={columns}
              data={data}
              setFilteredRows={setFilteredRows}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerServiceRecords;
