import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import {
  Button,
  CloseButton,
  Card,
  Modal,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

import {
  SelectFilter,
  NumberRangeFilter,
  DateRangeFilter,
} from "components/filters/filters";

import { FilterSortSelectTable as Table } from "components/table/tables";
import { getCurrentUser } from "context/Authorization";


import ActionButton from "components/common/ActionButton";
import { useAPI2 } from "hooks/useFetch";

const Archives = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const userColumns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "username",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: SelectFilter,
      },
      {
        Header: "Company",
        accessor: "company.name",
        Filter: SelectFilter,
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("users");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("users");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const companyColumns = useMemo(
    () => [
      {
        Header: "Company",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Contact",
        accessor: "contactPerson",
      },
      {
        Header: "Phone",
        accessor: "contactNumber",
      },
      {
        Header: "Email",
        accessor: "contactEmail",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("companies");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("companies");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const siteColumns = useMemo(
    () => [
      {
        Header: "Company",
        accessor: "company.name",
      },
      {
        Header: "Site",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Contact",
        accessor: "contactPerson",
      },
      {
        Header: "Phone",
        accessor: "contactNumber",
      },
      {
        Header: "Email",
        accessor: "contactEmail",
      },
      {
        Header: "Travel Time",
        accessor: "travelTime",
      },
      {
        Header: "Exam",
        accessor: (d) => d.workplaceExam.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Active",
        accessor: (d) => d.active.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("sites");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("sites");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const machineColumns = useMemo(
    () => [
      {
        accessor: "site.name",
        Header: "Site",
      },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        Header: "Active",
        accessor: (d) => d.active.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("machines");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("machines");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const tagColumns = useMemo(
    () => [
      {
        Header: "Site",
        accessor: "site.name",
      },
      {
        Header: "Machine",
        accessor: "machine.name",
      },
      {
        Header: "Part",
        accessor: "servicePart",
      },
      {
        Header: "Tag Number",
        accessor: "tagNumber",
        Filter: NumberRangeFilter,
        filter: "between",
      },
      {
        Header: "Sequence Number",
        accessor: "sequenceNumber",
        disableFilters: true,
      },
      {
        Header: "Alloted Time",
        accessor: "allotedMinutes",
        disableFilters: true,
      },
      {
        Header: "Active",
        accessor: (d) => d.active.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("tags");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("tags");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const ticketColumns = useMemo(
    () => [
      {
        Header: "Site",
        accessor: "site.name",
      },
      {
        Header: "Ticket",
        accessor: "ticketName",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Alloted Time",
        accessor: "allotedMinutes",
        disableFilters: true,
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ cell: { value } }) => <div>{value.length}</div>,
      },
      {
        Header: "Active",
        accessor: (d) => d.active.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("tickets");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("tickets");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const serviceRecordcolumns = useMemo(
    () => [
      {
        Header: "Tech",
        accessor: "userName",
      },
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Ticket",
        accessor: "ticketName",
      },
      {
        Header: "Machine",
        accessor: "machineName",
      },
      {
        Header: "Part",
        accessor: "partServiced",
      },
      {
        Header: "Time Stamp",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Released",
        accessor: (d) => d.released.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("servicerecords");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("servicerecords");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const workRecordColumns = useMemo(
    () => [
      {
        Header: "Tech",
        accessor: "user.username",
      },
      {
        Header: "Site",
        accessor: "site.name",
      },
      {
        Header: "Ticket",
        accessor: "ticket.ticketName",
      },
      {
        Header: "Started",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Complete",
        accessor: (d) => d.complete.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Released",
        accessor: (d) => d.released.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("workrecords");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("workrecords");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const serviceIssueColumns = useMemo(
    () => [
      {
        Header: "Tech",
        accessor: "userName",
      },
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Machine",
        accessor: "machineName",
      },
      {
        Header: "Part",
        accessor: "servicePart",
      },
      {
        Header: "Issue",
        accessor: "issueName",
      },
      {
        Header: "Priority",
        accessor: "issuePriority",
      },
      {
        Header: "Time Stamp",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Resolved",
        accessor: (d) => d.resolved.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Released",
        accessor: (d) => d.released.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("serviceissues");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("serviceissues");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const siteIssueColumns = useMemo(
    () => [
      {
        Header: "Tech",
        accessor: "userName",
      },
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Machine",
        accessor: "machineName",
      },
      {
        Header: "Tag Number",
        accessor: "tagNumber",
      },
      {
        Header: "Issue",
        accessor: "issueName",
      },
      {
        Header: "Priority",
        accessor: "issuePriority",
      },
      {
        Header: "Time Stamp",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Resolved",
        accessor: (d) => d.resolved.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Released",
        accessor: (d) => d.released.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("siteissues");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("siteissues");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );
  const timeColumns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "userName",
      },
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Ticket",
        accessor: "ticketName",
      },
      {
        Header: "Type",
        Filter: SelectFilter,
        accessor: "timeType",
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        Filter: DateRangeFilter,
        filter: "between",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.startTime);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Stop Time",
        accessor: "stopTime",
        Filter: DateRangeFilter,
        filter: "between",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.stopTime);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Elapsed Time (minutes)",
        disableFilters: true,
        accessor: "minutes",
        Cell: (rowData) => {
          const item = rowData.row.original;
          let elapsedTime = 0;

          if (item.stopTime) {
            elapsedTime = Math.floor(
              (item.stopTime - item.startTime) / (60 * 1000)
            );
          }
          return <p>{elapsedTime}</p>;
        },
      },
      {
        Header: "Restore",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Restore"
              variant="action"
              onClick={() => {
                setItemType("timerecords");
                setSelected([item._id]);
                setShowRestoreModal(true);
              }}
              className="p-0 me-2 text-success"
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={() => {
                setItemType("timerecords");
                setSelected([item._id]);
                setShowDeleteModal(true);
              }}
              className="p-0 me-2 text-danger"
            />
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, loading, error, callAPI] = useAPI2();

  const users = useMemo(() => result.users || [], [result]);
  const companies = useMemo(() => result.companies || [], [result]);
  const sites = useMemo(() => result.sites || [], [result]);
  const machines = useMemo(() => result.machines || [], [result]);
  const tags = useMemo(() => result.tags || [], [result]);
  const tickets = useMemo(() => result.tickets || [], [result]);
  const servicerecords = useMemo(() => result.servicerecords || [], [result]);
  const workrecords = useMemo(() => result.workrecords || [], [result]);
  const serviceissues = useMemo(() => result.serviceissues || [], [result]);
  const siteissues = useMemo(() => result.siteissues || [], [result]);
  const timerecords = useMemo(() => result.timerecords || [], [result]);

  const [update, setUpdate] = useState(true);

  // Select & Release
  const [selected, setSelected] = useState([]);
  const [itemType, setItemType] = useState(null);

  // Item Modal
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //Fetch Data
  useEffect(() => {
    if (update) {
      callAPI({
        path: `/archive`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Logout unauthorized users
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = async () => {
    console.log(selected);
    console.log(itemType);

    callAPI({
      path: "/archive",
      method: "DELETE",
      payload: { items: selected, itemType: itemType, userName: user.username, userId: user.id },
    });
    setUpdate(true);
  };

  const handleRestore = async () => {
    callAPI({
      path: "/unarchive/",
      method: "PATCH",
      payload: { items: selected, itemType: itemType },
    });
    setUpdate(true);
  };

  function RestoreItemModal() {
    return (
      <>
        <Modal
          show={showRestoreModal}
          onHide={() => setShowRestoreModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Restore Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => {
                setShowRestoreModal(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">Are you sure you want to restore record(s)?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                handleRestore();
                setShowRestoreModal(false);
              }}
            >
              Restore
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowRestoreModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function DeleteItemModal() {
    return (
      <>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Delete Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Are you sure you want to delete this record? This cannot be
              undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleDelete();
                setShowDeleteModal(false);
              }}
            >
              Delete
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {result.message && <Alert variant="success">{result.message}</Alert>}
      {loading && <Alert variant="warning">{loading}</Alert>}
      {showRestoreModal && <RestoreItemModal />}
      {showDeleteModal && <DeleteItemModal />}

      {/* Title */}
      <Card className="mb-5">
        <Card.Header className="text-center h3 border-bottom">
          <h2>Archives</h2>
        </Card.Header>
      </Card>

      {users.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">User Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("users");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("users")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={userColumns}
                data={users}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {companies.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Company Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("companies");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("companies")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={companyColumns}
                data={companies}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {sites.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Site Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("sites");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("sites")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={siteColumns}
                data={sites}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {machines.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Machine Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("machines");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("machines")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={machineColumns}
                data={machines}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {tags.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Tag Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("tags");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("tags")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={tagColumns}
                data={tags}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {tickets.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Tickets Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("tickets");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("tickets")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={ticketColumns}
                data={tickets}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {servicerecords.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Service Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("servicerecords");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("servicerecords")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={serviceRecordcolumns}
                data={servicerecords}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {workrecords.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Work Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("workrecords");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("workrecords")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={workRecordColumns}
                data={workrecords}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {serviceissues.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Service Issue Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("serviceissues");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("serviceissues")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={serviceIssueColumns}
                data={serviceissues}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {siteissues.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Site Issue Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("siteissues");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("siteissues")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={siteIssueColumns}
                data={siteissues}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}

      {timerecords.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="d-flex justify-content-center">
              <h3 className="mb-3 text-center">Time Records</h3>
              <Row className="mb-3 text-start">
                <Col>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setItemType("timerecords");
                      setShowRestoreModal("true");
                    }}
                  >
                    Restore Selected Records
                  </Button>
                  <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={() => {
                        setItemType("timerecords")
                        setShowDeleteModal("true")
                      }}
                    >
                      Delete Selected Records
                    </Button>
                </Col>
              </Row>
              <Table
                columns={timeColumns}
                data={timerecords}
                updateSelected={setSelected}
              />
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default Archives;
