import React from "react";

import FalconCardHeader from "components/common/FalconCardHeader";
import { Card } from "react-bootstrap";
import Statistics from "./Statistics";

const ProjectStatistics = ({ data }) => {
  return (
    <>
      {data.map((site, index) => {
        return (
          <div key={index}>
            <Card>
              <FalconCardHeader
                title={`${site?.name} - Maintenance Statistics`}
                titleTag="h4"
              />
              <Card.Body className="pt-0">
                <Statistics site={site} />
              </Card.Body>
            </Card>
            <br />
          </div>
        );
      })}
    </>
  );
};

export default ProjectStatistics;
