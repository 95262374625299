import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import {
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";

const CustomerIssueForm = ({ item, handleForm }) => {
  // State
  const [formData, setFormData] = useState({
    _id: "",
    issueDetails: "",
    issuePriority: "",
    resolved: false,
    images: [],
  });

  // Initialize Form
  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id,
        issueDetails: item.issueDetails,
        issuePriority: item.issuePriority,
        resolved: item.resolved,
        images: item.images,
      });
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    handleForm(formData);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-5">
        <Col>
          <h5 className="mb-5">{item.siteName}</h5>
          <h5 className="mb-2">{`${item.machineName && item.machineName} 
          ${item.tagNumber && item.tagNumber > 0 && `at tag ${item.tagNumber}` }`}</h5>
          <h5 className="mb-2">{item.issueName}</h5>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Issue Priority</Form.Label>
            <Form.Select
              placeholder="priority"
              value={formData.issuePriority}
              name="issuePriority"
              onChange={handleFieldChange}
            >
              <option value="routine">Routine</option>
              <option value="important">Important</option>
              <option value="critical">Critical</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Issue Status</Form.Label>
            <Form.Check
              type="switch"
              checked={formData.resolved}
              name="resolved"
              label="Resolved"
              onChange={handleSwitch}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2 mb-3">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Issue Details</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Additional details (optional)"
              rows={3}
              name="issueDetails"
              onChange={handleFieldChange}
              value={formData.issueDetails}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Extract this into a component */}
      <Row>
        <div className="d-flex flex-row">
          {(formData.images && formData.images).map((img, index) => (
            <div key={index} className="w-25 m-2 d-flex flex-column border rounded">
              <img
                src={img}
                alt=""
                onClick={() => {
                  console.log("Ahh!");
                }}
              />
            </div>
          ))}
        </div>
      </Row>
      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          onClick={handleSubmit}
        >
          Update Record
        </Button>
      </Form.Group>
    </Form>
  );
};

CustomerIssueForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default CustomerIssueForm;
