import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Section from "components/common/Section";
// import Logo from "components/common/Logo";
import logo from "assets/img/3tm-logo-white.png";

import { userLogout } from "context/Authorization";

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
  userLogout();

  return (
    <>
      <Section className="py-0">
        <Row className="flex-center min-vh-100 py-6">
          <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
            <Card >
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              {/* <Logo /> */}
              <Card.Title className="p-4" style={{ backgroundColor: "#7a96b3" }}>
            <img
              className="d-block mx-auto my-4"
              src={logo}
              alt="sent"
              width={100}
            />
          </Card.Title>
              <Card.Body className="p-4 p-sm-5">
                <Card.Title className="mb-3 text-center">
                  See you again!
                </Card.Title>

                <Card.Text className="text-center">Thanks for using 3 Tier Maintenance.</Card.Text>

              <div className="mb-3 text-center">
                <Button
                  as={Link}
                  color="primary"
                  size="sm"
                  className="mt-3"
                  to={`/`}
                >
                  <FontAwesomeIcon
                    icon="chevron-left"
                    transform="shrink-4 down-1"
                    className="me-1"
                  />
                  Return to Login
                </Button>
              </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Section>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string,
};

LogoutContent.defaultProps = {
  layout: "simple",
  titleTag: "h4",
};

export default LogoutContent;
