import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import Flex from "components/common/Flex";
import Section from "components/common/Section";
import logo from "assets/img/3tm-logo-white.png";

import { useLogin } from "hooks/useAuth";

const Login = () => {
  const [, loading, error, login] = useLogin();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    login(formData.email, formData.password);
  };

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Card>
          <Card.Title className="p-4" style={{ backgroundColor: "#7a96b3" }}>
            <img
              className="d-block mx-auto my-4"
              src={logo}
              alt="sent"
              width={100}
            />
          </Card.Title>
            <Card.Body className="p-4 p-sm-5">
              <Flex
                justifyContent="between"
                alignItems="center"
                className="mb-2"
              >
                <h5>Log in</h5>
              </Flex>

              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Email"
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="text"
                  />
                </Form.Group>

                <Form.Group className="mb-1">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    placeholder="Password"
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                  />
                </Form.Group>

                {/* <Divider className="mt-4"></Divider> */}

                  <Link to="/forgot-password" className="text-right">
                    Forgot your password?
                  </Link>

                <Form.Group>
                  <Button
                    type="submit"
                    color="primary"
                    className="mt-4 w-100"
                    disabled={!formData.email || !formData.password}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span className="m-3">Log in</span>
                  </Button>
                </Form.Group>
              </Form>

              {error && (
                <div className="form-group mt-3">
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default Login;
