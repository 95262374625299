/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import routes from 'routes/routes';
// import { useRoutes } from "hooks/useRoutes";
// import { flatRoutes } from 'helpers/utils';

// User Pages
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';
import LogoutContent from 'components/pages/authentication/LogoutContent'

//Misc Pages
// import ComingSoon from 'components/pages/ComingSoon';
// import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import Terms from 'components/pages/terms';


// Customer Pages
import CustomerServiceRecords from 'components/pages/customer/servicerecords'
import CustomerMachines from 'components/pages/customer/machines'
import CustomerServiceIssues from 'components/pages/customer/serviceissues'
import CustomerSiteIssues from 'components/pages/customer/siteissues'
import CustomerTrips from 'components/pages/customer/trips'
import CustomerExams from 'components/pages/customer/exams'

// Review Pages
import UserWorkRecords from 'components/pages/review/workrecords';
import UserTimeRecords from 'components/pages/review/timerecords';
import UserServiceRecords from 'components/pages/review/servicerecords';
import ServiceIssues from 'components/pages/review/serviceissues';
import SiteIssues from 'components/pages/review/siteissues';


// Setup Pages
import CompanySetup from 'components/pages/setup/companies';
import SiteSetup from 'components/pages/setup/sites';
import MachineSetup from 'components/pages/setup/machines';
import TagSetup from 'components/pages/setup/tags';
import TicketSetup from 'components/pages/setup/tickets';

// Admin Pages
import AdminTimeReview from 'components/pages/admin/timerecords'
import UserSetup from 'components/pages/admin/userrecords'
import Archives from 'components/pages/admin/archives'


// const inActiveRoutes = flatRoutes(routes).filter(route => !route.active);

const MainRoutes = () => (
  <Switch>

    {/* Customer */}
    <Route path="/customer/servicerecords" exact component={CustomerServiceRecords} />
    <Route path="/customer/machines" exact component={CustomerMachines} />
    <Route path="/customer/serviceissues" exact component={CustomerServiceIssues} />
    <Route path="/customer/siteissues" exact component={CustomerSiteIssues} />
    <Route path="/customer/trips" exact component={CustomerTrips} />
    <Route path="/customer/exams" exact component={CustomerExams} />

    {/* Review */}
    <Route path="/review/workrecords" exact component={UserWorkRecords} />
    <Route path="/review/timerecords" exact component={UserTimeRecords} />
    <Route path="/review/servicerecords" exact component={UserServiceRecords} />
    <Route path="/review/serviceissues" exact component={ServiceIssues} />
    <Route path="/review/siteissues" exact component={SiteIssues} />

    {/* Setup */}
    <Route path="/setup/companies" exact component={CompanySetup} />
    <Route path="/setup/sites" exact component={SiteSetup} />
    <Route path="/setup/machines" exact component={MachineSetup} />
    <Route path="/setup/tags" exact component={TagSetup} />
    <Route path="/setup/tickets" exact component={TicketSetup} />

    {/* Admin */}
    <Route path="/admin/timerecords" exact component={AdminTimeReview} />
    <Route path="/admin/users" exact component={UserSetup} />
    <Route path="/admin/archives" exact component={Archives} />


    {/*Pages*/}
    <Route path="/user/profile" exact component={Profile} />
    <Route path="/user/logout" exact component={LogoutContent} />
    <Route path="/user/settings" exact component={Settings} />
    <Route path="/terms" exact component={Terms} />

    {/* <Route
      path="/miscellaneous/privacy-policy"
      exact
      component={PrivacyPolicy}
    /> */}


    {/* <Route
      path={inActiveRoutes.map(route => route.to)}
      exact
      component={ComingSoon}
    /> */}

    {/*Redirect*/}
    <Redirect to="/errors/404" />
  </Switch>
);

export default MainRoutes;
