import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import Section from "components/common/Section";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import envelope from "assets/img/icons/spot-illustrations/16.png";
import logo from "assets/img/3tm-logo-white.png";

const ConfirmMail = () => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
        <Card>
        <Card.Title className="p-4" style={{ backgroundColor: "#7a96b3" }}>
            <img
              className="d-block mx-auto my-4"
              src={logo}
              alt="sent"
              width={100}
            />
          </Card.Title>
          <Card.Body className="px-4 pt-4">
            <div className="text-center">
              <img
                className="d-block mx-auto mb-4"
                src={envelope}
                alt="sent"
                width={100}
              />
              <h3>Please check your email!</h3>
              <p>
                An email has been sent to the address you provided. Please
                click on the included link to reset your password.
              </p>
              <Button
                as={Link}
                color="primary"
                size="sm"
                className="mt-3"
                to={`/login`}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  transform="shrink-4 down-1"
                  className="me-1"
                />
                Return to login
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Section>
);

export default ConfirmMail;
