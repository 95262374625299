import React, { useState, useEffect, useMemo } from "react";
import { Button, CloseButton, Card, Modal, Row, Alert } from "react-bootstrap";

import { SelectFilter } from "components/filters/filters";
import { FilterSortTable as Table } from "components/table/tables";
import { useAPI2 } from "hooks/useFetch";

import ActionButton from "components/common/ActionButton";
import UserRegistrationForm from "components/forms/UserRegistrationForm";

const UserSetup = () => {
  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "username",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: SelectFilter,
      },
      {
        Header: "Company",
        accessor: "company.name",
        Filter: SelectFilter,
      },
      {
        Header: "Sites",
        accessor: "sites",
        Cell: ({ cell: { value } }) => <Sites values={value} />,
      },
      {
        Header: "Edit",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Edit"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowItemModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
      {
        Header: "Archive",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Archive"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowArchiveModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  // Custom component to render Sites
  const Sites = ({ values }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <>
        {values.map((site, idx) => {
          return (
            <span key={idx} className="ms-1 badge bg-success">
              {site.name}
            </span>
          );
        })}
      </>
    );
  };

  const [result, , error, callAPI] = useAPI2();
  const [, , dError, callArchive] = useAPI2();
  const [, , uError, callUpdate] = useAPI2();
  const [, , , sendInvitation] = useAPI2();

  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(true);

  const [item, setItem] = useState(null);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  //Fetch Data
  useEffect(() => {
    if (update) {
      callAPI({
        path: `/auth/users`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleForm = async (data) => {
    setShowItemModal(false);
    const url = data._id ? `/auth/user/id/${data._id}` : "/auth/invite";
    const method = data._id ? "PATCH" : "POST";

    console.log("handleForm: ", data)

    callUpdate({
      path: url,
      method: method,
      payload: data,
    });

    setUpdate(true);
  };

  const handleArchive = async (id) => {
    const data = {_id: id, archived: true}

    callArchive({
      path: `/auth/archive/id/${id}`,
      method: "PATCH",
      payload: data
    });
    setUpdate(true);
  };
  
  function ItemModal() {
    return (
      <>
        <Modal
          show={showItemModal}
          onHide={() => setShowItemModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                {item ? "Edit User " : "Add User"}
              </h4>
              <p className="fs--1 mb-0 text-white">
                {item ? "Update user account" : "Add user account"}
              </p>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowItemModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-4">
            <UserRegistrationForm
              layout="split"
              item={item}
              handleRegister={handleForm}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowItemModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function ArchiveItemModal() {
    console.log(item);
    return (
      <>
        <Modal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Archive Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowArchiveModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Are you sure you want to archive the record for {item.username}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleArchive(item._id);
                setShowArchiveModal(false);
              }}
            >
              Archive
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowArchiveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {dError && <Alert variant="danger">{dError}</Alert>}
      {uError && <Alert variant="danger">{uError}</Alert>}

      {showItemModal && <ItemModal />}
      {showArchiveModal && <ArchiveItemModal />}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          User Records
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <div className="mb-3 mx-3 text-end">
            <Button
              variant="primary"
              onClick={() => {
                setItem(null);
                setShowItemModal(true);
              }}
            >
              Add User
            </Button>
          </div>

          <Row className="d-flex justify-content-center">
            <Table columns={columns} data={data} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserSetup;
