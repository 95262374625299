import React from "react";
import Select from "react-select";

export function TextFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="w-100"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function SelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export function NumberRangeFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

export function DateRangeFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);

      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div>
      <input
        min={min.toISOString().slice(0, 10)}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? val : undefined, old[1]]);
        }}
        type="date"
        value={filterValue[0] || ""}
        style={{
          width: "140px",
        }}
      />
      {" to "}
      <input
        max={max.toISOString().slice(0, 10)}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? val.concat("T23:59:59.999Z") : undefined,
          ]);
        }}
        type="date"
        value={filterValue[1]?.slice(0, 10) || ""}
        style={{
          width: "140px",
        }}
      />
    </div>
  );
}

export function MultiSelect({ data, column, selected, setSelected }) {
  let uniques = [{ value: "", label: "" }];

  if (data && data.length > 0) {
    uniques = [...new Set(data.map((r) => r[column]))].map((r) => {
      return { value: r, label: r };
    });
  }
  return (
    <Select
      defaultValue={selected}
      isMulti
      name="colors"
      options={uniques}
      className="basic-multi-select my-3"
      classNamePrefix="select"
      onChange={setSelected}
    />
  );
}

export function MultiSelectID({
  data,
  column,
  value,
  label,
  selected,
  setSelected,
}) {
  let uniques = [{ value: "", label: "" }];

  if (data && data.length > 0) {
    uniques = data
      .map((r) => r[column])
      .map((r) => {
        if (r) {
          return { value: r[value], label: r[label] };
        }
      })
      .filter((value, index, self) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          self.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      })
      .filter((item) => {
        return item !== undefined;
      });
  }

  return (
    <Select
      defaultValue={selected}
      isMulti
      name="colors"
      options={uniques}
      className="basic-multi-select my-3"
      classNamePrefix="select"
      onChange={setSelected}
    />
  );
}

export const key_picker = (keys, obj) =>
  keys.reduce((res, key) => {
    res[key] = obj[key] || "";
    return res;
  }, {});

export const row_builder = (keys, obj) =>
  keys.reduce((res, key) => {
    let val = obj[key] || "";

    if (key === "timestamp") {
      const date = new Date(val);
      val = date.toDateString();
    }

    res.push(val);
    return res;
  }, []);
