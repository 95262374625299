import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import {
  Button,
  CloseButton,
  Card,
  Col,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";
import ActionButton from "components/common/ActionButton";
import { getCurrentUser } from "context/Authorization";
import { useAPI2 } from "hooks/useFetch";
import { DateRangeFilter, MultiSelectID } from "components/filters/filters";

import { FilterSortTable as Table } from "components/table/tables";
import MyPDF from "components/forms/CustomerPrintable_react-pdf";

const WorkRecords = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = React.useMemo(
    () => [
      {
        Header: "Site",
        accessor: "site.name",
      },
      {
        Header: "Date",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toDateString()}</p>;
        },
      },
      {
        Header: "Print Report",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="file-alt"
              title="Open PDF"
              variant="action"
              onClick={() => {
                // setItem(item);
                fetchExam(item);
                setShowPrintModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, , error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const [exam, , eError, fetchExamData] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(false);

  // Edit Item
  const [item, setItem] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);

  // Prefilters
  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({ user: user.id });
    fetchSites({
      path: `/customer/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map((s) => {
      return { site: s };
    });
    setSiteList(mysites);
  }, [sites]);

  // Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      user: user.id,
      sites: selectedSites.map((s) => s.value),
    });

    if (update) {
      callAPI({
        path: `/customer/workrecords/?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Workplace Exam
  const fetchExam = (record) => {
    const filters = JSON.stringify({
      user: user.id,
      ticket: record._id,
    });

    fetchExamData({
      path: `/customer/exam/?filters=${filters}`,
      method: "GET",
    });
  };

  // Set the workplace exam as the current item
  useEffect(() => {
    if (exam?._id) {
      setItem(exam);
    }
  }, [exam]);

  // Logout unauthorized users
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  function PrintModal() {
    return (
      <>
        <Modal
          show={showPrintModal}
          onHide={() => setShowPrintModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Print Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => {
                setShowPrintModal(false);
              }}
            />
          </Modal.Header>
          <Modal.Body className="p-4">
            <MyPDF item={item} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowPrintModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}

      {showPrintModal && (
        <PrintModal
          showModal={showPrintModal}
          setShowModal={setShowPrintModal}
        />
      )}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Review Work Records
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Select Site and Load Data</h5>

            <Col className="align-self-center">
              <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>

            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Load Data
              </Button>
            </Col>
          </Row>

          <Row className="d-flex py-3 my-3 justify-content-center border rounded-3">
            <Table columns={columns} data={data} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default WorkRecords;
