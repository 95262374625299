import React, { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";

import PropTypes from "prop-types";

import {
  Button,
  Form,
  Row,
  Col,
  CloseButton,
} from "react-bootstrap";

const SiteIssueForm = ({ item, handleForm }) => {
  // State
  const [formData, setFormData] = useState({
    userName: "",
    siteName: "",
    machineName: "",
    servicePart: "",
    issueName: "",
    issueDetails: "",
    issuePriority: "",
    resolved: false,
    released: false,
    timestamp: 0,
    tagId: "",
    tagNumber: 0,
    siteId: "",
    images: [],
  });

  // Initialize Form
  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id,
        userName: item.userName,
        siteName: item.siteName,
        machineName: item.machineName,
        servicePart: item.servicePart,
        issueName: item.issueName,
        issueDetails: item.issueDetails,
        issuePriority: item.issuePriority,
        resolved: item.resolved,
        released: item.released,
        timestamp: item.timestamp,
        tagId: item.tagId,
        tagNumber: item.tagNumber,
        siteId: item.siteId,
        images: item.images,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
 
  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    handleForm(formData);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const saveImages = async (e) => {
    try {
      const file = e.target.files[0];
      const image = await resizeFile(file);
      setFormData({
        ...formData,
        images: [image, ...formData.images],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteImage = (i) => {
    let imgs = formData.images
    console.log(imgs.length)

    if (i > -1) {
      imgs.splice(i, 1)
      console.log(imgs.length)

      setFormData({
        ...formData,
        images: [imgs]
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-5">
        <Col>
          <h5>
            {formData.siteName} {formData.machineName} {formData.tagNumber > 0 && `at tag ${formData.tagNumber}`}
          </h5>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Issue Name</Form.Label>
            <Form.Control
              type="text"
              name="issueName"
              onChange={handleFieldChange}
              value={formData.issueName}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Issue Priority</Form.Label>
            <Form.Select
              placeholder="priority"
              value={formData.issuePriority}
              name="issuePriority"
              onChange={handleFieldChange}
            >
              <option value="routine">Routine</option>
              <option value="important">Important</option>
              <option value="critical">Critical</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Issue Status</Form.Label>
            <Form.Check
              type="switch"
              checked={formData.resolved}
              name="resolved"
              label="Resolved"
              onChange={handleSwitch}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Released</Form.Label>
            <Form.Check
              type="switch"
              checked={formData.released}
              name="released"
              label="Released"
              onChange={handleSwitch}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2 mb-3">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Issue Details</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Additional details (optional)"
              rows={3}
              name="issueDetails"
              onChange={handleFieldChange}
              value={formData.issueDetails}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Extract this into a component */}
      <Row>
        <div className="d-flex flex-row">
          {(formData.images && formData.images).map((img, index) => (
            <div key={index} className="w-25 m-2 d-flex flex-column border rounded">
              <div className="text-end">
                <CloseButton onClick={() => deleteImage(index)} />
              </div>
              <img
                src={img}
                alt=""
                onClick={() => {
                  console.log("Ahh!");
                }}
              />
            </div>
          ))}
        </div>
      </Row>

      <Row>
        <Form.Group controlId="formFileMultiple" className="mb-3">
          <Form.Label>Add Images</Form.Label>
          <Form.Control
            type="file"
            accept="image/*;capture=camera"
            onChange={(e) => saveImages(e)}
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.userName ||
            !formData.siteName ||
            !formData.issueName ||
            !formData.issuePriority ||
            !formData.timestamp ||
            !formData.siteId
          }
          onClick={handleSubmit}
        >
          {item ? "Update Record" : "Add Record"}
        </Button>
      </Form.Group>
    </Form>
  );
};

SiteIssueForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default SiteIssueForm;
