import React, { useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Form, Row, Col } from "react-bootstrap";

import { useAPI2 } from "hooks/useFetch";

const UserRegistrationForm = ({ item, handleRegister }) => {
  // State
  const [formData, setFormData] = useState({
    _id: "",
    username: "",
    email: "",
    type: "",
  });

  const dropdowns = [
    { value: "customer", name: "Customer" },
    { value: "tech", name: "Tech" },
    { value: "admin", name: "Admin" },
    { value: "superadmin", name: "Super Admin" },
  ];

  const [companies, , , getCompanies] = useAPI2();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [availableSites, setAvailableSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  // fetch companies
  useEffect(() => {
    console.log("getting companies");
    getCompanies({
      path: `/companies`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Prefill form for existing users
  useEffect(() => {
    if (item && item.username && item.email) {
      setFormData({
        _id: item._id,
        username: item.username,
        email: item.email,
        type: item.type,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Prefill the company when company fetch is complete
  useEffect(() => {
    if (item && item.company) {
      const company = companies.find((c) => c._id === item.company);
      company && setSelectedCompany(company._id);
    }
  }, [companies]); // eslint-disable-line react-hooks/exhaustive-deps

  // Populated selected and available sites lists when company is changed
  useEffect(() => {
    const company = companies.find((c) => c._id === selectedCompany);
    
    if (company) {
      setAvailableSites(
        company.sites.map((r) => {
          return { value: r._id, label: r.name };
        })
      );
    }

    if (company && item && item.sites) {
      let sites = company.sites.filter((s) => {
        return item.sites.includes(s._id);
      })
      .map((s) => {
        return { value: s._id, label: s.name };
      });

      setSelectedSites(sites)
    }
  }, [selectedCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();

    let data = formData;

    if (selectedCompany) {
      data = {
        ...data,
        company: selectedCompany,
      };
    }

    if (selectedSites) {
      const sites = selectedSites.map((s) => s.value);
      data = {
        ...data,
        sites: sites,
      };
    }

    console.log("form submitted");
    handleRegister(data);
    toast.success(`Successfully registered ${formData.name}`);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Control value={formData._id} name="_id" type="hidden" />

            <Form.Label>User Name</Form.Label>
            <Form.Control
              placeholder="Name"
              value={formData.username}
              name="username"
              onChange={handleFieldChange}
              type="text"
              autocomplete="on"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              placeholder="Email address"
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="text"
              autocomplete="on"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Account Type</Form.Label>
            <Form.Select
              placeholder="Account Type"
              value={formData.type}
              name="type"
              onChange={handleFieldChange}
            >
              <option></option>
              {dropdowns.map((e, i) => {
                return <option key={i} value={e.value}>{e.name}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      { formData.type === "customer" && 
        <Row className="g-2 mb-3">
        <Col className="align-self-start">
          <Form.Group>
            <Form.Label>Company</Form.Label>
            <Form.Select
              placeholder="Company"
              value={selectedCompany}
              onChange={(e) => {
                setSelectedCompany(e.target.value);
              }}
            >
              <option></option>
              {companies &&
                companies.map((e) => {
                  return <option value={e._id}>{e.name}</option>;
                })}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="align-self-start">
          <Form.Group>
            <Form.Label>Sites</Form.Label>
            <Select
              value={selectedSites}
              isMulti
              name="sites"
              options={availableSites}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedSites}
            />
          </Form.Group>
        </Col>
      </Row>
      }

      <Form.Group className="mt-4">
        <Button
          className="w-100"
          // type="submit"
          disabled={
            !formData.username ||
            !formData.email
          }
          onClick={handleSubmit}
        >
          {item ? "Update User" : "Invite User"}
        </Button>
      </Form.Group>
    </Form>
  );
};

UserRegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default UserRegistrationForm;
