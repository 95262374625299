import React from "react";
import { Col, Row } from "react-bootstrap";

const Statistics = ({ site }) => {
  return (
    <>
      <Row className="mb-2">
        <Col className="text-center">
          <h4 className="mb-0 text-danger">{site.siteIssueCount}</h4>
          <p className="fs--1 mb-0 text-danger">Site Issues</p>
        </Col>

        <Col className="border-end text-center border-200">
          <h4 className="mb-0 text-primary">{site.machineCount}</h4>
          <p className="fs--1 text-primary mb-0">Active Machines</p>
        </Col>

        <Col className="border-end text-center border-200">
          <h4 className="mb-0 text-success ">{site.tagCount}</h4>
          <p className="fs--1 text-success mb-0">Service Points</p>
        </Col>

        <Col className="text-center">
          <h4 className="mb-0 text-danger">{site.serviceIssueCount}</h4>
          <p className="fs--1 mb-0 text-danger">Service Issues</p>
        </Col>
      </Row>
    </>
  );
};

export default Statistics;
