import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Row, Col } from "react-bootstrap";

const CompanyRecordForm = ({ item, handleForm }) => {
  // State
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    contactPerson: "",
    contactNumber: "",
    contactEmail: "",
    active: true,
  });

  // Initialize Form
  useEffect(() => {
    setFormData({
      _id: item._id,
      name: item.name,
      address: item.address,
      contactPerson: item.contactPerson,
      contactNumber: item.contactNumber,
      contactEmail: item.contactEmail,
      active: item.active,
    });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    handleForm(formData);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Control value={formData._id} name="_id" type="hidden" />
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={handleFieldChange}
              value={formData.name}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              onChange={handleFieldChange}
              value={formData.address}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Contact Person</Form.Label>
            <Form.Control
              type="text"
              name="contactPerson"
              onChange={handleFieldChange}
              value={formData.contactPerson}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              name="contactNumber"
              onChange={handleFieldChange}
              value={formData.contactNumber}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2">
        <Form.Group as={Col} sm={6}>
          <Form.Label>Contact Email</Form.Label>
          <Form.Control
            type="text"
            name="contactEmail"
            onChange={handleFieldChange}
            value={formData.contactEmail}
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <Form.Label>Status</Form.Label>
          <Form.Select
            aria-label="Select User"
            value={formData.userName}
            name="userName"
            onChange={handleFieldChange}
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </Form.Select>
        </Form.Group>
      </Row>

      {item && (

        <Row className="g-2 mt-4">
          <h5>Company Sites</h5>
          {item.sites.map((r) => {
            return <div>{r.name}</div>;
          })}
        </Row>
      )}

      <Form.Group className="mb-1 mt-5">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.name ||
            !formData.address ||
            !formData.contactPerson ||
            !formData.contactNumber ||
            !formData.contactEmail
          }
          onClick={handleSubmit}
        >
          {item ? "Update Record" : "Add Record"}
        </Button>
      </Form.Group>
    </Form>
  );
};

CompanyRecordForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default CompanyRecordForm;
