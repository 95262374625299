import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import {
  Button,
  CloseButton,
  Card,
  Modal,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

import {
  SelectFilter,
  DateRangeFilter,
  MultiSelect,
} from "components/filters/filters";

import { FilterSortTable as Table } from "components/table/tables";

import ActionButton from "components/common/ActionButton";
import TimeRecordForm from "components/forms/TimeRecordForm";
import { useAPI2 } from "hooks/useFetch";

const AdminTimeReview = () => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "userName",
      },
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Ticket",
        accessor: "ticketName",
      },
      {
        Header: "Type",
        Filter: SelectFilter,
        accessor: "timeType",
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        Filter: DateRangeFilter,
        filter: "between",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.startTime);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Stop Time",
        accessor: "stopTime",
        Filter: DateRangeFilter,
        filter: "between",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.stopTime);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Elapsed Time (minutes)",
        disableFilters: true,
        accessor: "minutes",
        Cell: (rowData) => {

          const item = rowData.row.original;
          let elapsedTime = 0

          if (item.stopTime) {
            elapsedTime = Math.floor(
              (item.stopTime - item.startTime) / (60 * 1000)
            );
          }
          return <p>{elapsedTime}</p>;
        },

        Footer: (info) => {
          const minuteSum = info.filteredRows.reduce((sum, row) => {
            let minutes = 0
            if (row.values.stopTime) {
              minutes = Math.floor(
                (row.values.stopTime - row.values.startTime) / (60 * 1000)
              );
            } 
            return sum + minutes;
          }, 0);
          return <b>{minuteSum}</b>;
        },
      },
      {
        Header: "Edit",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Edit"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowItemModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
      {
        Header: "Archive",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Archive"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowArchiveModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, loading, error, callAPI] = useAPI2();
  const [, , aError, callArchive] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(true);

  const [item, setItem] = useState(null);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  // Prefilters
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      users: selectedUsers.map((u) => u.value),
      sites: selectedSites.map((s) => s.value),
      types: selectedTypes.map((t) => t.value),
    });

    if (update) {
      callAPI({
        path: `/timerecords?filters=${filters}`,
        method: "GET",
        payload: null,
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Data after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleForm = async (data) => {
    setShowItemModal(false);
    callAPI({
      path: data._id ? `/timerecord/id/${data._id}` : "/timerecord",
      method: data._id ? "PATCH" : "POST",
      payload: data,
    });
    setUpdate(true);
  };

  const handleArchive = async (id) => {
    const data = {_id: id, archived: true}
    callArchive({
      path: "/timerecord/archive/id/" + id,
      method: "PATCH",
      payload: data
    });
    setUpdate(true);
  };

  function ItemModal() {
    return (
      <>
        <Modal
          show={showItemModal}
          onHide={() => setShowItemModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                {item ? "Edit Record" : "Add Record"}
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowItemModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-4">
            <TimeRecordForm
              layout="split"
              item={item}
              handleForm={handleForm}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowItemModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function DeleteItemModal() {
    return (
      <>
        <Modal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Delete Time Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => {
                setShowArchiveModal(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Are you sure you want to delete this record? This cannot be
              undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleArchive(item._id);
                setShowArchiveModal(false);
              }}
            >
              Delete
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowArchiveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function ArchiveItemModal() {
    console.log(item);
    return (
      <>
        <Modal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Archive Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowArchiveModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Are you sure you want to archive the record for {item.userName}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleArchive(item._id);
                setShowArchiveModal(false);
              }}
            >
              Archive
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowArchiveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {result.message && <Alert variant="success">{result.message}</Alert>}
      {loading && <Alert variant="warning">{loading}</Alert>}
      {showItemModal && <ItemModal item={item} />}
      {showArchiveModal && <ArchiveItemModal item={item} />}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Time Records
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <div className="mb-3 text-end">
          </div>
          <Row className="mb-3 text-center">
            <Col>
              <MultiSelect
                data={data}
                column={"userName"}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
              />
            </Col>
            <Col>
              <MultiSelect
                data={data}
                column={"siteName"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>
            <Col>
              <MultiSelect
                data={data}
                column={"timeType"}
                selected={selectedTypes}
                setSelected={setSelectedTypes}
              />
            </Col>
            <Col className="align-self-center">
              <Button
                variant="outline-primary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Update
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Table columns={columns} data={data} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AdminTimeReview;
