import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Card, Button, Form, Row, Col, Alert } from "react-bootstrap";
import Flex from "components/common/Flex";
import Section from "components/common/Section";
import { useAPI2 } from "hooks/useFetch";
import logo from "assets/img/3tm-logo-white.png";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [result, loading, error, callAPI] = useAPI2();

  useEffect(() => {
    console.log("result: ", result)
    if (result.message && !error) {
      history.push("/login");
    }
  }, [result, error, history]);

  // Handler
  const onSubmit = (data) => {
    const payload = {
      password: data.password,
      token: query.get('token'),
      email: query.get('email'),
    }

    if (!payload.email || !payload.token) {
      console.log("missing query params");
      history.push("/login");
    }
    
    callAPI({
      path: "/auth/reset-password",
      method: "POST",
      payload: payload,
    });

    
  };

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          {error && <Alert variant="danger">{error}</Alert>}
          <Card>
          <Card.Title className="p-4" style={{ backgroundColor: "#7a96b3" }}>
            <img
              className="d-block mx-auto my-4"
              src={logo}
              alt="sent"
              width={100}
            />
          </Card.Title>
            <Card.Body className="p-4 p-sm-5">
              <Flex
                justifyContent="between"
                alignItems="center"
                className="mb-2"
              >
                <h5>Set New Password</h5>
              </Flex>

              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* Password */}
                <Col className="mb-3">
                  <Form.Group as={Col} className="mb-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Password is required",
                        },
                        minLength: {
                          value: 10,
                          message: "Password must have min length of 10",
                        },
                      })}
                    />

                    <div className="text-sm text-danger">{errors?.password?.message}</div>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      {...register("confirm", {
                        required: {
                          value: true,
                          message: "Confirm password is required",
                        },
                        validate: () => getValues("password") === getValues("confirm")
                      })}
                    />
                    <div className="text-sm text-danger">{errors?.confirm?.message}</div>
                    {errors.confirm && errors.confirm.type === "validate" && (
                      <div className="text-danger">
                        Confirm Password must match Password
                      </div>)}
                  </Form.Group>
                </Col>

                <Form.Group className="my-4">
                  <Button
                    className="w-100"
                    type="submit"
                  >{loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span className="m-3">Set Password</span>
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default ResetPassword;
