import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import {
  Button,
  CloseButton,
  Card,
  Col,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";
import ActionButton from "components/common/ActionButton";
import ServiceIssueForm from "components/forms/ServiceIssueForm";

import { getCurrentUser } from "context/Authorization";
import { useAPI2 } from "hooks/useFetch";
import {
  SelectFilter,
  DateRangeFilter,
  MultiSelect,
  MultiSelectID,
} from "components/filters/filters";

import { FilterSortSelectTable as Table } from "components/table/tables";

const CustomerIssueRecords = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = React.useMemo(
    () => [
      {
        Header: "Tech",
        accessor: "userName",
      },
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Machine",
        accessor: "machineName",
      },
      {
        Header: "Part",
        accessor: "servicePart",
      },
      {
        Header: "Issue",
        accessor: "issueName",
      },
      {
        Header: "Priority",
        accessor: "issuePriority",
      },
      {
        Header: "Time Stamp",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Resolved",
        accessor: (d) => d.resolved.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Released",
        accessor: (d) => d.released.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Details",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <>
              <ActionButton
                icon="file"
                title="Details"
                variant="action"
                onClick={() => {
                  setItem(item);
                  setShowItemModal(true);
                }}
                className="p-0 me-2"
              />
            </>
          );
        },
      },
      {
        Header: "Archive",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="trash-alt"
              title="Archive"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowArchiveModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, , error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const [, , , updateRecords] = useAPI2();
  const [, , , archiveRecords] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(false);

  // Select & Release
  const [selected, setSelected] = useState([]);
  const [status, setStatus] = useState(null);

  // Prefilters
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  // const [selectedMachines, setSelectedMachines] = useState([]);

  // Item Modal
  const [item, setItem] = useState(null);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);

  // Verify user is authorized
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({});
    fetchSites({
      path: `/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map(s => {
      return { "site": s }
    })
    setSiteList(mysites);
  }, [sites]);


  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      ...(user && { user: user.id }),
      // users: selectedUsers.map((u) => u.value),
      sites: selectedSites.map((s) => s.value),
      // machines: selectedMachines.map((m) => m.value),
    });

    if (update) {
      callAPI({
        path: `/serviceissues?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // release records
  const handleUpdate = () => {
    updateRecords({
      path: `/serviceissues/release`,
      method: "PATCH",
      payload: {
        ids: selected,
        status: status,
      },
    });
    setUpdate(true);
  };

  const handleForm = async (data) => {
    setShowItemModal(false);
    console.log(data);
    updateRecords({
      path: data._id ? `/serviceissue/id/${data._id}` : "/serviceissue",
      method: data._id ? "PATCH" : "POST",
      payload: data,
    });
    setUpdate(true);
  };

  const handleArchive = async (id) => {
    const data = { _id: id, archived: true };
    archiveRecords({
      path: "/serviceissue/archive/id/" + id,
      method: "PATCH",
      payload: data,
    });
    setUpdate(true);
  };

  function ItemModal() {
    return (
      <>
        <Modal
          show={showItemModal}
          onHide={() => setShowItemModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                {item ? "Edit Record" : "Add Record"}
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowItemModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-4">
            <ServiceIssueForm item={item} handleForm={handleForm} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowItemModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function ArchiveItemModal() {
    console.log(item);
    return (
      <>
        <Modal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Archive Record
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowArchiveModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Are you sure you want to archive the record for {item.issueName}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleArchive(item._id);
                setShowArchiveModal(false);
              }}
            >
              Archive
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowArchiveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function ReleaseItemModal() {
    return (
      <>
        <Modal
          show={showReleaseModal}
          onHide={() => setShowReleaseModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                {`${status ? "Release" : "Restrict"} selected records`}
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowReleaseModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              {`Are you sure you want to ${status ? "release" : "restrict"
                } the selected records?`}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleUpdate();
                setShowReleaseModal(false);
              }}
            >
              Continue
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowReleaseModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}

      <Card>
        {result.error && <Alert variant="danger">{result.error}</Alert>}
        {result.message && <Alert variant="success">{result.message}</Alert>}
        {showItemModal && <ItemModal item={item} />}
        {showArchiveModal && <ArchiveItemModal item={item} />}
        {showReleaseModal && <ReleaseItemModal />}

        <Card.Header className="text-center h3 border-bottom">
          Review Issues
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Select Site and Load Data</h5>
            {/* <Col>
              <MultiSelect
                data={data}
                column={"userName"}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
              />
            </Col> */}


            <Col>
              <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>

            {/* <Col>
              <MultiSelect
                data={data}
                column={"machineName"}
                selected={selectedMachines}
                setSelected={setSelectedMachines}
              />
            </Col> */}

            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Load Data
              </Button>
            </Col>
          </Row>

          {/* {selectedSites.length === 0 &&
          <Row className="mb-3 text-start">
            <p className="text-center">Select Site(s) and click Update Table to load data</p>
          </Row>
          } */}

          <Row className="d-flex py-3 my-3 justify-content-center border rounded-3">
            {(user && user.type === "admin") ||
              (user.type === "superadmin" && (
                <Row className="mb-3 text-start">
                  <Col>
                    <Button
                      variant="outline-warning"
                      onClick={() => {
                        setStatus(false);
                        setShowReleaseModal(true);
                      }}
                    >
                      Restrict Selected Records
                    </Button>
                    <Button
                      className="ms-3"
                      variant="outline-success"
                      onClick={() => {
                        setStatus(true);
                        setShowReleaseModal(true);
                      }}
                    >
                      Release Selected Records
                    </Button>
                  </Col>
                </Row>
              ))}

            <Table columns={columns} data={data} updateSelected={setSelected} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerIssueRecords;
