import { useState, useCallback } from "react";
import { useHistory } from "react-router";
import { useRoutes } from "hooks/useRoutes";
import { API_URL } from "../App";

export const useLogin = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [, updateRoutes] = useRoutes();

  const login = useCallback((email, password) => {
    setData([]);
    setLoading(true);
    setError(error);

    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    };

    fetch(API_URL + "/auth/signin", request)
      .then((response) =>
        response
          .json()
          .then((data) => ({
            json: data,
            status: response.status,
          }))
          .then(({ status, json }) => {
            if (status === 429) {
              localStorage.removeItem("user");
              history.push("/login");
              throw json;
            }
            if (status < 200 || status > 299) {
              console.log(json);
              throw json;
            }
            if (json) {
              localStorage.setItem("user", JSON.stringify(json));
              setLoading(false);
              setError(null);

              if (!json.acceptedTerms) {
                history.push("/terms");
                throw new Error ("terms not accepted")
              }
            }
          })
          .then(() => {
            updateRoutes();
          })
          .then(() => {
            updateRoutes();
          })
          .then(() => {
            history.push("/");
          })
      )
      .catch((error) => {
        console.log(error);
        setData([]);
        setLoading(false);
        setError(error.message);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, loading, error, login];
};

export const useAcceptTerms = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [, updateRoutes] = useRoutes();

  const acceptTerms = useCallback((user) => {
    setData([]);
    setLoading(true);
    setError(error);

    console.log(user)

    const headers = {
      "Content-Type": "application/json",
      ...(user) && {"x-access-token": user.accessToken }   
    } 

    const request = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({_id: user.id}),
    };
  
    fetch(API_URL + "/auth/terms/id/" + user.id, request)
      .then((response) =>
        response
          .json()
          .then((data) => ({
            json: data,
            status: response.status,
          }))
          .then(({ status, json }) => {
            if (status === 429) {
              history.push("/login");
              throw json;
            }
            if (status < 200 || status > 299) {
              console.log(json);
              throw json;
            }
            if (json) {
              console.log(json)
              localStorage.setItem("user", JSON.stringify(json.user));
              setLoading(false);
              setError(null);
  
              if (!json.user.acceptedTerms) {
                history.push("/terms");
                throw new Error ("terms not accepted")
              }
            }
          })
          .then(() => {
            updateRoutes();
          })
          .then(() => {
            updateRoutes();
          })
          .then(() => {
            history.push("/");
          })
      )
      .catch((error) => {
        console.log(error);
        setData([]);
        setLoading(false);
        setError(error.message);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return [data, loading, error, acceptTerms];
};
