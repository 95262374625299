import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import {
  Button,
  Form,
  Row,
  Col,
  Alert
} from "react-bootstrap";
import { useAPI2 } from "hooks/useFetch";

const MachineRecordForm = ({ item, selectedSites }) => {
  // State
  const [formData, setFormData] = useState({
    name: "",
    site: "",
    loto: "",
    active: true,
  });

  const [data, , , callAPI] = useAPI2();
  const [newItem, , , addItem] = useAPI2();
  const [sites, setSites] = useState([]);

  // Get sites & prefill selected site from table filter
  useEffect(() => {
    if (selectedSites.length > 0) {
      setFormData({
        ...formData,
        site: selectedSites[0].value,
      });
    }
    callAPI({
      path: `/sites`,
      method: "GET",
    });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSites(data);
  }, [data]);  // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize Form
  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id,
        name: item.name,
        site: item.site._id,
        loto: item.loto,
        active: item.active,
      });
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // Add Machine
  const handleSubmit = (e) => {
    e.preventDefault();
    // handleForm(formData);

    addItem({
      path: formData._id ? `/machine/id/${formData._id}` : "/machine",
      method: formData._id ? "PATCH" : "POST",
      payload: formData,
    });
  };

  // Confirm machine added
  useEffect(() => {
    console.log(newItem)
  }, [newItem]);

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {newItem.name && <Alert variant="success">{newItem.name}</Alert>}
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Site</Form.Label>
            <Form.Select
              placeholder="Company"
              value={formData.site}
              name="site"
              onChange={handleFieldChange}
            >
              <option></option>
              {sites.length > 0 &&
                sites.map((e) => {
                  return <option value={e._id}>{e.name}</option>;
                })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Machine Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              rows={3}
              onChange={handleFieldChange}
              value={formData.name}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>LoTo</Form.Label>
            <Form.Control
              type="text"
              name="loto"
              onChange={handleFieldChange}
              value={formData.loto}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2 mb-3">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Machine Status</Form.Label>
            <Form.Check
              type="switch"
              checked={formData.active}
              name="active"
              label="Active"
              onChange={handleSwitch}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={!formData.name || !formData.site || !formData.loto}
          onClick={handleSubmit}
        >
          {item ? "Update Record" : "Add Record"}
        </Button>
      </Form.Group>
    </Form>
  );
};

MachineRecordForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default MachineRecordForm;
