import { API_URL } from "../App";

//TODO: Add authorization
export function signup(username, email, password, type) {
  const record = {
    username,
    email,
    password,
    type,
  };

  fetch(API_URL + "/auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(record),
  })
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.error(err);
    });
}

export function userLogout() {
  localStorage.removeItem("user");
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  return {
    "Content-Type": "application/json",
    ...(user) && {"x-access-token": user.accessToken }   
  }
}
