import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Button, Col, Row } from 'react-bootstrap';
import coverSrc from 'assets/img/generic/industrial.jpg';
import avatar from 'assets/img/team/avatar.png';
import { getCurrentUser } from "context/Authorization";

import ProfileBanner from './ProfileBanner';
import VerifiedBadge from 'components/common/VerifiedBadge';

const Banner = () => {
  const history = useHistory();
  const user = getCurrentUser();

   // Ensure user logged in
   useEffect(() => {
    if (!user) {
      console.log("no user logged in");
      history.push("/login");
    }
  });

  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {user && user.username} <VerifiedBadge />
            </h4>
            <h5 className="fs-0 fw-normal">
              {user && user.type}
            </h5>
            <p className="text-500">{user && user.email}</p>
            <div className="border-dashed-bottom my-4 d-lg-none" />
            <Button variant="falcon-default" size="sm" className="px-3"  as={Link} to="/logout">
              Logout
            </Button>
            <Button variant="falcon-primary" size="sm" className="px-3 ms-2"  as={Link} to="/">
              Home
            </Button>
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
