import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Card, Alert } from "react-bootstrap";
import Divider from "components/common/Divider";

import { getCurrentUser } from "context/Authorization";
import { userLogout } from "context/Authorization";
import { useAcceptTerms } from "hooks/useAuth";

const Terms = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const [, loading, error, acceptTerms] = useAcceptTerms();

  // Ensure user logged in
  useEffect(() => {
    if (!user) {
      console.log("no user logged in");
      history.push("/login");
      //log user out so that they cannot access app
    }
    if (!user?.acceptedTerms) {
      console.log("temporary logout")
      userLogout();
    }
  });

  const handleAccept = async () => {
    acceptTerms(user);
  };

  return (
    <div className="d-flex justify-content-center mt-5 ">
      <div className="col-md-10 col-sm-12 mx-auto">
      {error && <Alert variant="danger">{error}</Alert>}
        <Card className="mb-3 mx-5">
          <Card.Header className="bg-light">
            <h5 className="mb-0 text-center">TERMS AND CONDITIONS</h5>
          </Card.Header>
          <Card.Body>
            <ol type="1">
              <li>
                <strong>Terms and Conditions. </strong> <br />
                The services and/or products provided by Zimmerman Industrial,
                Inc. (“Zimmerman”) are performed and/or provided to the user
                hereof (the “User”) via the this web application (the
                “Application”) exclusively under these Terms and Conditions set
                forth hereinbelow (the “Terms”). By User’s use of the
                Application, User expressly agrees to be bound by these Terms
                and indicates its continued acceptance of these Terms.
              </li>
              <br />
              <li>
                {" "}
                <strong>License.</strong> <br />
                Subject to these Terms, Zimmerman hereby grants User a
                nonexclusive and nontransferable license to use the Application
                only to the extent necessary to [maintain records and
                proactively schedule maintenance for industrial machinery]. Any
                and all uses of the Application that are not authorized pursuant
                to the terms of these Terms shall not be permitted without the
                prior written consent of Zimmerman. No permission or license is
                granted or implied to use the trademarks, logos, service marks
                and trade names displayed in the Application or on any
                print-outs or data outputs created by the Application
                (collectively, the “Logos”). The Logos may not be used without
                Zimmerman’s prior written consent.
              </li>
              <br />
              <li>
                <strong>Ownership.</strong>
                <br />
                User agrees that ownership of the Logos, the Application, along
                with all of its component parts, (including but not limited to
                any proprietary source code and object code, ideas, designs,
                methods, processes, databases, data, know-how, software,
                programs, content, and graphics, etc.), shall remain vested in
                Zimmerman. User further agrees never to challenge, contest, or
                question the validity of Zimmerman’s ownership of the
                Application, including, without limitation, to any improvements
                or enhancements made thereto by any party (if any). No rights or
                licenses are granted to User or any other party except for those
                as set forth herein.
              </li>
              <br />
              <li>
                <strong>Use.</strong>
                <br />
                User agrees to use the Application only for lawful purposes and
                in accordance with these Terms. User shall not use the
                Application for any unlawful purpose or any purpose prohibited
                by these Terms or not expressly authorized by these Terms. User
                agrees to comply with any applicable local, state, and federal
                laws, government rules and regulations. In addition to the
                foregoing, User agrees not to:
                <br />
                <ol type="a">
                  <br />
                  <li>
                    input, upload, transmit, to or otherwise communicate via the
                    Application any material which (i) could be deemed to be
                    obscene, indecent, discriminatory, offensive, defamatory,
                    harassing or threatening, (ii) infringe the copyright or
                    trademark rights of another party, (iii) is a virus,
                    ransomware, Trojan horse, worm, malware, or any other
                    harmful or destructive content;
                  </li>
                  <br />
                  <li>
                    interfere with any other person’s use of the Application or
                    access any other person’s information via the Application,
                    or collect or harvest any information or data from the
                    Application by any method;
                  </li>
                  <br />
                  <li>
                    interfere with the Application, its network, its security
                    features, or otherwise attempt to gain unauthorized access
                    to any other computer and/or network system via the
                    Application;
                  </li>
                  <br />
                  <li>
                    knowingly input or transmit any inaccurate information;
                  </li>
                  <br />
                  <li>
                    to reproduce, duplicate, copy or re-sell the Application or
                    any part of the Application;
                  </li>
                  <br />
                  <li>
                    make alterations to, or modifications of, the Application
                    (or any portion thereof), or permit the Application (or any
                    portion thereof) be combined with, or incorporated within
                    any other software or programs; and/or
                  </li>
                  <br />
                  <li>
                    disassemble, decompile, reverse-engineer or create
                    derivative works based on the Application (or any portion
                    thereof).
                  </li>
                </ol>{" "}
                <br />
                User hereby agrees Zimmerman shall not be liable to User for any
                loss that User may incur as a result of an unauthorized
                third-party access to User’s account.
              </li>

              <br />
              <li>
                <strong>User Responsibilities.</strong>
                <br />
                <ol type="a">
                  <li>
                    maintaining the confidentiality of its log-in credentials
                    for use of the Application with at least the same degree of
                    care that it uses to protect its own confidential and
                    propriety information, but no less than a reasonable degree
                    of care under the circumstances;{" "}
                  </li>
                  <br />
                  <li>
                    ensuring all activities which occur pursuant to User’s
                    account (whether or not same are performed by User) comply
                    with applicable laws and these Terms; and
                  </li>
                  <br />
                  <li>entering correct information into the Application. </li>
                  <br />
                </ol>
              </li>
              <br />
              <li>
                <strong>Right to Monitor.</strong>
                <br />
                Zimmerman shall have the right, but shall not be obligated to,
                monitor User’s access, history, and use of the Application from
                time to time or at any time, in any case without any prior
                notice to User.
              </li>
              <br />
              <li>
                <strong>Termination.</strong>
                <br />
                Zimmerman shall have the right to terminate, change, suspend or
                discontinue the Application at any time, and shall further have
                the right to terminate, change, suspend or discontinue these
                Terms or User’s access to the Application at any time, for any
                reason, in any case without any liability to Zimmerman.
                <br />
              </li>
              <br />
              <li>
                <strong>Remedies for Breach.</strong>
                <br />
                The parties understand and agree that money damages would not be
                a sufficient remedy for any breach of these Terms and that
                Zimmerman shall be entitled, in addition to such monetary
                damages, to seek equitable relief by way of preliminary and/or
                permanent injunction, specific performance or any other
                equitable relief to remedy or forestall any such breach or
                threatened breach by User. Such remedy shall not be deemed to be
                the exclusive remedy for any breach of these Terms but shall be
                in addition to all other rights and remedies available at law or
                in equity. The parties further acknowledge and agree that the
                covenants contained herein are necessary for the protection of
                legitimate business interests and re reasonable in scope.
                <br />
              </li>
              <br />
              <li>
                <strong>No Warranties.</strong>
                <br />
                THE APPLICATION IS BEING PROVIDED TO USER ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. USER ACKNOWLEDGES AND AGREES THAT, TO THE
                FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ZIMMERMAN DISCLAIMS
                ALL WARRANTIES, REPRESENTATIONS, AND ENDORSEMENTS, WHETHER
                EXPRESS OR IMPLIED, WITH REGARD TO THE APPLICATION, INCLUDING
                WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE,
                MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR
                PURPOSE. ZIMMERMAN MAKES NO WARRANTY THAT THE APPLICATION WILL
                BE UNINTERRUPTED OR ERROR FREE.
                <br />
              </li>
              <br />
              <li>
                <strong>Limitation of Liability.</strong>
                <br />
                TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, USER AGREES THAT IT
                IS USING THE APPLICATION AT ITS OWN RISK, AND THAT ZIMMERMAN
                (ALONG WITH ZIMMERMAN’S SHAREHOLDERS, OFFICERS, DIRECTORS,
                AGENTS AND EMPLOYEES) SHALL IN NO WAY BE LIABLE TO USER FOR ANY
                LOSS, INJURY OR DAMAGES (INCLUDING, BUT NOT LIMITED TO, ANY
                SPECIAL, INDIRECT, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOST
                PROFITS, LOST REVENUES, OR OTHER INCIDENTAL DAMAGES) ARISING OUT
                OF THE USE, INABILITY TO USE, OR ANY DATA OUTPUTS, OF THE
                APPLICATION.
                <br />
              </li>
              <br />
              <li>
                <strong>Indemnity. </strong>
                <br />
                User hereby assumes all responsibility for and agrees to
                indemnify, defend and hold harmless Zimmerman, along with its
                shareholders, officers, employees, and agents from and against
                any and all damages, losses, claims, suits, or other expenses
                whatsoever, including, without limitations, Zimmerman’s
                reasonable attorneys’ fees, arising out of (i) User’s breach of
                its obligations hereunder, (ii) any other violation of these
                Terms on the part of User, and/or (iii) User’s use of the
                Application. The indemnification obligations herein shall
                survive termination of these Terms for any reason.
                <br />
              </li>
              <br />
              <li>
                <strong>No Waiver; Amendment; Exclusive Agreement. </strong>
                <br />
                No failure or delay by Zimmerman in exercising any right, power
                or privilege hereunder shall operate as a waiver thereof, nor
                shall any single or partial exercise thereof preclude any other
                or further exercise thereof or the exercise of any other right,
                power or privilege hereunder. These Terms may be amended from
                time to time by Zimmerman, and no prior notice of any such
                amendment(s) shall be required to be delivered to User. These
                Terms represent the entire agreement between Zimmerman and User
                regarding the use of the Application.
                <br />
              </li>
              <br />
              <li>
                <strong>Assignment.</strong>
                <br />
                User may not transfer or assign (or have any right to transfer
                or assign) these its rights under these Terms, any of its log-in
                credentials for use and access to the Application, or any of its
                rights or interest thereunder, whether directly or indirectly,
                by operation of law, acquisition, merger, as a result of
                liquidation or dissolution, or otherwise, without the prior
                written consent of Zimmerman, which may be withheld for any or
                no reason, in the sole discretion of Zimmerman, and any such
                attempted transfer or assignment will be null and void and
                without effect.
                <br />
              </li>
              <br />
              <li>
                <strong>Governing Law; Jurisdiction. </strong>
                <br />
                These Terms shall be governed by the laws of the Commonwealth of
                Pennsylvania. Each party agrees that exclusive jurisdiction and
                venue for any litigation concerning this Agreement shall exist
                in the state and federal courts having jurisdiction in Lancaster
                County, Pennsylvania.
                <br />
              </li>
            </ol>
            <Divider className="mt-4"></Divider>
            <div className="text-center">
              {!user?.acceptedTerms ? 
              <Button 
              type="submit" 
              className="mt-3" 
              disabled={user?.acceptedTerms}
              onClick={handleAccept}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span className="m-3">Accept and Continue</span>
              </Button>
              :
              <Button 
              className="mt-3" 
              onClick={history.goBack}>
                <span className="m-3">Return</span>
              </Button>
              }
              
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Terms;
