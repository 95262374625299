import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Row, Col } from "react-bootstrap";

const UserRegistrationForm = ({ item, handleForm }) => {
  // State
  const [formData, setFormData] = useState({
    _id: "",
    userName: "",
    siteName: "",
    ticketName: "",
    userId: "",
    workrecordId: "",
    timeType: "",
    startTime: null,
    stopTime: null,
  });

  useEffect(() => {
    if (item) {
      const start = new Date(item.startTime);
      const stop = new Date(item.stopTime);

      setFormData({
        _id: item._id,
        userName: item.userName,
        siteName: item.siteName,
        ticketName: item.ticketName,
        userId: item.userId,
        workrecordId: item.workrecordId,
        timeType: item.timeType,
        startTime: start.toISOString().slice(0, 16),
        stopTime: stop.toISOString().slice(0, 16),
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...formData,
      startTime: Date.parse(formData.startTime),
      stopTime: Date.parse(formData.stopTime),
    };

    handleForm(data);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Control value={formData._id} name="_id" type="hidden" />
            <Form.Label>User</Form.Label>
            <Form.Select
              aria-label="Select User"
              value={formData.userName}
              name="userName"
              onChange={handleFieldChange}
            >
              <option></option>
              <option value="Kenny Rogers">Kenny Rogers</option>
              <option value="George Jones">George Jones</option>
              <option value="Hank Williams">Hank Williams</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Site</Form.Label>
            <Form.Select
              aria-label="Select Site"
              value={formData.siteName}
              name="siteName"
              onChange={handleFieldChange}
            >
              <option>Select Site</option>
              <option value="New Enterprise - Hinkletown">New Enterprise - Hinkletown</option>
              <option value="New Enterprise - Denver">
              New Enterprise - Denver
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Ticket</Form.Label>
            <Form.Select
              aria-label="Select Ticket"
              value={formData.ticketName}
              name="ticketName"
              onChange={handleFieldChange}
            >
              <option>Select ticket</option>
              <option value="Hinkletown Weekly PM">Hinkletown Weekly PM</option>
              <option value="Hinkletown Monthly PM">
                Hinkletown Monthly PM
              </option>
              <option value="Denver Weekly PM">Denver Weekly PM</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Time Type</Form.Label>
            <Form.Select
              placeholder="Time Type"
              value={formData.timeType}
              name="timeType"
              onChange={handleFieldChange}
            >
              <option></option>
              <option value="travel">Travel</option>
              <option value="service">Service</option>
              <option value="break">Break</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          <Form.Label>Start Time</Form.Label>
          <Form.Control
            value={formData.startTime}
            name="startTime"
            type="datetime-local"
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <Form.Label>Stop Time</Form.Label>
          <Form.Control
            value={formData.stopTime}
            name="stopTime"
            type="datetime-local"
            onChange={handleFieldChange}
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.userName ||
            !formData.ticketName ||
            !formData.timeType ||
            !formData.startTime ||
            !formData.stopTime
          }
          onClick={handleSubmit}
        >
          {item ? "Update Record" : "Add Record"}
        </Button>
      </Form.Group>
    </Form>
  );
};

UserRegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default UserRegistrationForm;
