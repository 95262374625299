import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import {
  Button,
  CloseButton,
  Card,
  Col,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";
import { CSVLink } from "react-csv";

import {
  SelectFilter,
  DateRangeFilter,
  MultiSelect,
  MultiSelectID,
  row_builder,
} from "components/filters/filters";

import ActionButton from "components/common/ActionButton";
import CustomerIssueForm from "components/forms/CustomerServiceIssueDetail";
import { FilterSortDLTable as Table } from "components/table/tables";
import { getCurrentUser } from "context/Authorization";
import { useAPI2 } from "hooks/useFetch";

const CustomerSiteIssues = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = React.useMemo(
    () => [
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Machine",
        accessor: "machineName",
      },
      {
        Header: "Near Tag",
        accessor: "tagNumber",
      },
      {
        Header: "Issue",
        accessor: "issueName",
      },
      {
        Header: "Details",
        accessor: "issueDetails",
      },
      {
        Header: "Images",
        accessor: "images",
        Cell: (rowData) => {
          const count = rowData.row.original.images.length;
          return <p>{`${count} images`}</p>;
        },
      },
      {
        Header: "Priority",
        accessor: "issuePriority",
        Cell: (rowData) => {
          const priority = rowData.row.original.issuePriority;
          let style = "";
          switch (priority) {
            case "important":
              style = "text-warning";
              break;
            case "critical":
              style = "text-danger";
              break;
            default:
              break;
          }
          return <p className={style}>{priority}</p>;
        },
      },
      {
        Header: "Recorded Date",
        accessor: "timestamp",
        Filter: DateRangeFilter,
        filter: "dateBetween",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.timestamp);
          return <p>{date.toDateString()}</p>;
        },
      },
      {
        Header: "Resolved",
        accessor: (d) => d.resolved.toString(),
        Filter: SelectFilter,
        filter: "includes",
        Cell: (rowData) => {
          const status = rowData.row.original.resolved;
          return (
            <p className={status ? "text-success" : "text-danger"}>
              {status ? "resolved" : "open"}
            </p>
          );
        },
      },
      {
        Header: "Details",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <>
              <ActionButton
                icon="file"
                title="Details"
                variant="action"
                onClick={() => {
                  setItem(item);
                  setShowItemModal(true);
                }}
                className="p-0 me-2"
              />
            </>
          );
        },
      },
    ],
    []
  );

  // Table Data
  const [result, , error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const [uResult, , uError, updateRecords] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(false);

  // Prefilters
  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  // const [selectedMachines, setSelectedMachines] = useState([]);

  // Filtered Table Data
  const [filteredRows, setFilteredRows] = useState([]);
  const [CSV, setCSV] = useState([]);

  // Item Modal
  const [item, setItem] = useState(null);
  const [showItemModal, setShowItemModal] = useState(false);

  const handleForm = async (data) => {
    setShowItemModal(false);
    data = {
      ...data,
      type: "site",
    };

    console.log(data);

    updateRecords({
      path: `/customer/issue/id/${data._id}`,
      method: "PATCH",
      payload: data,
    });
    setUpdate(true);
  };

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({ user: user.id });
    fetchSites({
      path: `/customer/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      user: user.id,
      sites: selectedSites.map((s) => s.value),
      // machines: selectedMachines.map((m) => m.value),
    });

    if (update) {
      callAPI({
        path: `/customer/siteissues/?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map(s => {
      return { "site": s }
    })
    setSiteList(mysites);
  }, [sites]);

  // Verify authorization after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  // Export Data
  useEffect(() => {
    const cols = [
      "siteName",
      "machineName",
      "tagNumber",
      "issueName",
      "issueDetails",
      "issuePriority",
      "timestamp",
    ];
    const headers = [
      "Site",
      "Machine",
      "Tag",
      "Name",
      "Details",
      "Priority",
      "Date",
    ];

    const rows = filteredRows.map((r) => row_builder(cols, r));
    const csv = [headers, ...rows];

    setCSV(csv);
  }, [filteredRows]);

  function ItemModal() {
    return (
      <>
        {error && <Alert variant="danger">{error}</Alert>}

        <Modal
          show={showItemModal}
          onHide={() => setShowItemModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                {item ? "Edit Record" : "Add Record"}
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => setShowItemModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-4">
            <CustomerIssueForm item={item} handleForm={handleForm} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowItemModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Card>
        {showItemModal && <ItemModal item={item} />}

        <Card.Header className="text-center h3 border-bottom">
          Site Issues
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Select Site and Load Data</h5>
            <Col>
            <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>
            {/* <Col>
              <MultiSelect
                data={data}
                column={"machineName"}
                selected={selectedMachines}
                setSelected={setSelectedMachines}
              />
            </Col> */}
            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Load Data
              </Button>
            </Col>
            <Col className="align-self-center text-end">
              {/* <CSVLink
                data={CSV}
                filename={"Service Records.csv"}
                className="btn btn-outline-primary"
              >
                Download Records
              </CSVLink> */}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Table
              columns={columns}
              data={data}
              setFilteredRows={setFilteredRows}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerSiteIssues;
