import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { Card, Button, Form, Row, Col, Alert } from "react-bootstrap";
import Flex from "components/common/Flex";
import Section from "components/common/Section";
import { useAPI2 } from "hooks/useFetch";
import logo from "assets/img/3tm-logo-white.png";

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [result, loading, error, callAPI] = useAPI2();

  useEffect(() => {
    if (result?.message) {
      history.push(`/confirm-email?email=${email}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const url = `/auth/forgot-password`;
    const method = "POST";

    callAPI({
      path: url,
      method: method,
      payload: { email: email },
    });
  };

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          {error && <Alert variant="danger">{error}</Alert>}
          <Card>
          <Card.Title className="p-4" style={{ backgroundColor: "#7a96b3" }}>
            <img
              className="d-block mx-auto my-4"
              src={logo}
              alt="sent"
              width={100}
            />
          </Card.Title>
            <Card.Body className="p-4 p-sm-5">
              <Flex
                justifyContent="between"
                alignItems="center"
                className="mb-2"
              >
                <h5>Reset Password</h5>
              </Flex>
              <Form className="mt-4" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    placeholder={"Email address"}
                    value={email}
                    name="email"
                    onChange={({ target }) => setEmail(target.value)}
                    type="email"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Button
                    type="submit"
                    color="primary"
                    className="mt-3 w-100"
                    disabled={!email}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span className="m-3">Send Reset Link</span>
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

ForgotPassword.propTypes = {
  layout: PropTypes.string,
};

ForgotPassword.defaultProps = { layout: "simple" };

export default ForgotPassword;
