import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import {
  Button,
  Card,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

import {
  SelectFilter,
  DateRangeFilter,
  MultiSelect,
  MultiSelectID,
} from "components/filters/filters";

import { getCurrentUser } from "context/Authorization";
import { FilterSortTable as Table } from "components/table/tables";
import { useAPI2 } from "hooks/useFetch";

const UserTimeRecords = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "userName",
      },
      {
        Header: "Site",
        accessor: "siteName",
      },
      {
        Header: "Ticket",
        accessor: "ticketName",
      },
      {
        Header: "Type",
        Filter: SelectFilter,
        accessor: "timeType",
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        Filter: DateRangeFilter,
        filter: "between",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.startTime);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Stop Time",
        accessor: "stopTime",
        Filter: DateRangeFilter,
        filter: "between",
        Cell: (rowData) => {
          const date = new Date(rowData.row.original.stopTime);
          return <p>{date.toLocaleString()}</p>;
        },
      },
      {
        Header: "Elapsed Time (minutes)",
        disableFilters: true,
        accessor: "minutes",
        Cell: (rowData) => {

          const item = rowData.row.original;
          let elapsedTime = 0

          if (item.stopTime) {
            elapsedTime = Math.floor(
              (item.stopTime - item.startTime) / (60 * 1000)
            );
          }
          return <p>{elapsedTime}</p>;
        },

        Footer: (info) => {
          const minuteSum = info.filteredRows.reduce((sum, row) => {
            let minutes = 0
            if (row.values.stopTime) {
              minutes = Math.floor(
                (row.values.stopTime - row.values.startTime) / (60 * 1000)
              );
            }
            return sum + minutes;
          }, 0);
          return <b>{minuteSum}</b>;
        },
      },
    ],
    []
  );

  // Table Data
  const [result, loading, error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(true);

  // Prefilters
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      ...(user && { user: user.id }),
      users: selectedUsers.map((u) => u.value),
      sites: selectedSites.map((s) => s.value),
      types: selectedTypes.map((t) => t.value),
    });

    if (update) {
      callAPI({
        path: `/timerecords?filters=${filters}`,
        method: "GET",
        payload: null,
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({});
    fetchSites({
      path: `/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map(s => {
      return { "site": s }
    })
    setSiteList(mysites);
  }, [sites]);

  // Update Data after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {result.message && <Alert variant="success">{result.message}</Alert>}
      {loading && <Alert variant="warning">{loading}</Alert>}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Time Records
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <div className="mb-3 text-end">
          </div>
          <Row className="mb-3 text-center">
            <Col>
              <MultiSelect
                data={data}
                column={"userName"}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
              />
            </Col>
            <Col>
              <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>
            <Col>
              <MultiSelect
                data={data}
                column={"timeType"}
                selected={selectedTypes}
                setSelected={setSelectedTypes}
              />
            </Col>
            <Col className="align-self-center">
              <Button
                variant="outline-primary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Update
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Table columns={columns} data={data} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserTimeRecords;
