
export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  children: [
    {
      name: "Dashboard",
      to: "/",
      active: true,
      icon: "chart-pie",
    },
  ],
};

export const customerRoutes = {
  label: "Customer",
  children: [
    {
      name: "Machines",
      icon: "sun",
      to: "/customer/machines",
      active: true,
    },
    {
      name: "Service Records",
      icon: "file-alt",
      to: "/customer/servicerecords",
      active: true,
    },
    {
      name: "Service Issues",
      icon: "wrench",
      to: "/customer/serviceissues",
      active: true,
    },
    {
      name: "Site Issues",
      icon: "exclamation-triangle",
      to: "/customer/siteissues",
      active: true,
    },
    {
      name: "Service Trips",
      icon: "file",
      to: "/customer/trips",
      active: true,
    },
    {
      name: "Workplace Exams",
      icon: "file",
      to: "/customer/exams",
      active: true,
    },
  ],
};

export const reviewRoutes = {
  label: "Review",
  children: [
    {
      name: "Work Records",
      icon: "file",
      to: "/review/workrecords",
      active: true,
    },
    {
      name: "Time Records",
      icon: "clock",
      to: "/review/timerecords",
      active: true,
    },
    {
      name: "Service Records",
      icon: "file-alt",
      to: "/review/servicerecords",
      active: true,
    },
    {
      name: "Service Issues",
      icon: "wrench",
      to: "/review/serviceissues",
      active: true,
    },
    {
      name: "Site Issues",
      icon: "exclamation-triangle",
      to: "/review/siteissues",
      active: true,
    },
  ],
};

export const setupRoutes = {
  label: "Setup",
  children: [
    {
      name: "Companies",
      icon: "user",
      to: "/setup/companies",
      active: true,
    },
    {
      name: "Sites",
      icon: "map",
      to: "/setup/sites",
      active: true,
    },
    {
      name: "Machines",
      icon: "sun",
      to: "/setup/machines",
      active: true,
    },
    {
      name: "Tags",
      icon: "tag",
      to: "/setup/tags",
      active: true,
    },
    {
      name: "Tickets",
      icon: "file",
      to: "/setup/tickets",
      active: true,
    },
  ],
};

export const adminRoutes = {
  label: "Admin",
  children: [
    {
      name: "Time Records",
      icon: "clock",
      to: "/admin/timerecords",
      active: true,
    },
    {
      name: "Users",
      icon: "user",
      to: "/admin/users",
      active: true,
    },
  ],
};

export const archiveRoutes = {
  label: "Archives",
  children: [
    {
      name: "Archives",
      icon: "archive",
      to: "/admin/archives",
      active: true,
    },
  ],
}
