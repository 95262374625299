import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import is from 'is_js';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import MainLayout from './MainLayout';
import ErrorLayout from './ErrorLayout';
import Login from 'components/pages/authentication/Login';
import AccountSetup from 'components/pages/authentication/AccountSetup';
import ForgotPassword from 'components/pages/authentication/ForgotPassword';
import ResetPassword from 'components/pages/authentication/ResetPassword';
import ConfirmEmail from 'components/pages/authentication/ConfirmEmail';
import Terms from 'components/pages/terms'
import LogoutContent from  'components/pages/authentication/LogoutContent';


const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/account-setup" component={AccountSetup} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/confirm-email" component={ConfirmEmail} />
        <Route path="/terms" component={Terms} />
        <Route path="/logout" component={LogoutContent} />
        <Route path="/errors" component={ErrorLayout} />
        <Route component={MainLayout} />
        <Redirect to="/errors/404" />
      </Switch>
      <SettingsToggle />
      <SettingsPanel />
    </>
  );
};

export default Layout;
