import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Card, Col, Row } from "react-bootstrap";
import ProjectStatistics from "components/dashboards/project-management/project-statistics/ProjectStatistics";

import { getCurrentUser } from "context/Authorization";
import { useAPI2 } from "hooks/useFetch";

const Dashboard = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const [result, , , callAPI] = useAPI2();
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    if (!user) {
      history.push("/login")
      return;
    }

    const filters = JSON.stringify({
      ...(user) && {user: user.id},
    });

    if (update) {
      callAPI({
        path: `/sites/stats/?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Data after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="text-center">
          <Col className="text-center">
            <ProjectStatistics
              data={result}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Dashboard;
