/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button, Form, Row, Col, Alert } from "react-bootstrap";

import ActionButton from "components/common/ActionButton";
import { useAPI2 } from "hooks/useFetch";

const TagRecordForm = ({
  selectedSites,
  selectedMachines,
  filteredRows,
  tag
}) => {
  // State
  const [formData, setFormData] = useState({
    site: null,
    machine: null,
    servicePart: "",
    serviceTask: "",
    tagNumber: 0,
    sequenceNumber: 0,
    allotedMinutes: 0,
    customFields: [],
    active: true,
  });

  const [machineData, setMachineData] = useState({
    name: "",
    site: "",
    loto: "",
    active: true,
  });

  // Item Management
  const [item, setItem] = useState(null);
  const [index, setIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [isNew, setIsNew] = useState(false);

  const [sites, , , getSites] = useAPI2();
  const [newMachine, , , addMachine] = useAPI2();
  const [addedTag, , , addTag] = useAPI2();
  const [editedTag, , , editTag] = useAPI2();
  const [machines, setMachines] = useState([]);

  // Set rows and index
  useEffect(() => {
    setRows(filteredRows);

    if (!tag) {
      setIsNew(true);
      setIndex(filteredRows.length - 1);
    } else {
      const index = filteredRows.findIndex(r => r._id === tag._id)
      // console.log(index)
      setIndex(index);
    }
  }, [filteredRows, tag]); 

  //Prefill site & machine if they were passed in
  useEffect(() => {
    let site = null;
    let machine = null;

    if (selectedSites.length) site = selectedSites[0].value;
    if (selectedMachines.length) machine = selectedMachines[0].value;

    setFormData({
      ...formData,
      site: site,
      machine: machine,
    });

    setMachineData({
      ...machineData,
      site: site,
    });
  }, []); 

  // Fetch available sites
  useEffect(() => {
    getSites({
      path: `/sites`,
      method: "GET",
    });
  }, []); 

  // Set sites
  useEffect(() => {
    if (sites.length > 0 && formData.site) {
      const site = sites.find((s) => s._id === formData.site);
      if (site && site.machines) setMachines(site.machines);
    }
  }, [sites, formData.site]);

  // Set item from passed in rows, or when index changes
  useEffect(() => {
    // console.log(`Row Count: ${rows.length}`);
    // console.log(`Index ${index}`);
    // console.log(rows[index]);
    setItem(rows[index]);
  }, [index, rows]);

  // Initialize Form
  useEffect(() => {
    if (item) {
      setFormData({
        ...(!isNew && { _id: item._id }),
        site: item.site._id,
        machine: item.machine._id,
        servicePart: item.servicePart,
        serviceTask: item.serviceTask,
        tagNumber: isNew ? Number(item.tagNumber) + 1 : item.tagNumber,
        sequenceNumber: item.sequenceNumber,
        allotedMinutes: item.allotedMinutes,
        customFields: item.customFields,
        active: item.active,
      });
    }
  }, [item]); 

  // Navigate Items
  const nextTag = () => {
    let i = Number(index) + 1;
    i = i > rows.length - 1 ? 0 : i;
    setIndex(i);
  };

  const previousTag = () => {
    let i = Number(index) - 1;
    i = i < 0 ? rows.length - 1 : i;
    setIndex(i);
  };

  // Add a new Tag
  const handleNewTag = (e) => {
    e.preventDefault();
    addTag({
      path: "/tag",
      method: "POST",
      payload: formData,
    });
  };

  // Update an existing Tag
  const handleUpdateTag = (e) => {
    e.preventDefault();
    editTag({
      path: `/tag/id/${formData._id}`,
      method: "PATCH",
      payload: formData,
    });
  };

  // Confirm tag added
  useEffect(() => {
    if (addedTag._id) {
      setFormData({
        ...formData,
        tagNumber: Number(formData.tagNumber) + 1,
        sequenceNumber: Number(formData.sequenceNumber) + 1,
      });
    }
  }, [addedTag]);

  // Confirm tag updated
  useEffect(() => {
    if (editedTag._id) {
      let _rows = rows;
      _rows[index] = editedTag;

      setRows(_rows);
    }
  }, [editedTag]);

  // Add Machine
  const handleNewMachine = (e) => {
    e.preventDefault();

    const data = {
      ...machineData,
      site: formData.site,
    };

    addMachine({
      path: "/machine",
      method: "POST",
      payload: data,
    });
  };

  // Add newly created machine to list
  useEffect(() => {
    if (newMachine.name && newMachine._id) {
      setMachines([...machines, newMachine]);

      setFormData({
        ...formData,
        machine: newMachine._id,
      });
    }
  }, [newMachine]);

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMachineChange = (e) => {
    setMachineData({
      ...machineData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const addCustomField = (data) => {
    setFormData({
      ...formData,
      customFields: [...formData.customFields, data],
    });
  };

  const editCustomField = (data, i) => {
    let fields = [...formData.customFields];
    fields[i] = data;

    setFormData({
      ...formData,
      customFields: fields,
    });
  };

  const deleteCustomField = (i) => {
    if (i > -1) {
      let fields = [...formData.customFields];
      fields.splice(i, 1);

      setFormData({
        ...formData,
        customFields: fields,
      });
    }
  };

  const ExistingCustomField = ({ eField, index, update, remove }) => {
    const [field, setField] = useState(eField);

    useEffect(() => {
      setField(field);
    }, [field]);

    const handleEdit = (e) => {
      setField(
        {
          ...field,
          [e.target.name]: e.target.value,
        },
        index
      );
    };

    const handleEditSwitch = (e) => {
      setField(
        {
          ...field,
          [e.target.name]: e.target.checked,
        },
        index
      );
    };

    const updateRow = () => {
      update(field, index);
    };

    const handleDelete = () => remove(index);

    return (
      <>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Label</Form.Label>
              <Form.Control
                type="text"
                spellCheck={true}
                name="fieldLabel"
                onChange={handleEdit}
                onBlur={updateRow}
                value={field.fieldLabel}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                name="fieldValue"
                onChange={handleEdit}
                onBlur={updateRow}
                value={field.fieldValue}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Color</Form.Label>
              <Form.Control
                type="text"
                spellCheck={true}
                name="color"
                onChange={handleEdit}
                onBlur={updateRow}
                value={field.color}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Displayed</Form.Label>
              <Form.Check
                type="switch"
                checked={field.displayed}
                name="displayed"
                label="True"
                onChange={handleEditSwitch}
                onBlur={updateRow}
              />
            </Form.Group>
          </Col>
          <Col className="align-self-center">
            <ActionButton
              icon="trash-alt"
              title="Delete"
              variant="action"
              onClick={handleDelete}
              className="p-0 me-2"
            />
          </Col>
        </Row>
      </>
    );
  };

  const NewCustomField = ({ submit }) => {
    const [newField, setNewField] = useState({
      fieldLabel: "",
      fieldValue: "",
      color: "",
      displayed: true,
    });

    useEffect(() => {
      if (item) {
        setNewField(item);
      }
    }, [item]);

    const handleEdit = (e) => {
      setNewField({
        ...newField,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmit = () => {
      if (newField.fieldLabel || newField.fieldValue || newField.color) {
        newField.displayed = true;
        submit(newField);
      }
    };

    return (
      <>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Label</Form.Label>
              <Form.Control
                type="text"
                spellCheck={true}
                name="fieldLabel"
                onChange={handleEdit}
                onBlur={handleSubmit}
                value={newField.fieldLabel}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                spellCheck={true}
                name="fieldValue"
                onChange={handleEdit}
                onBlur={handleSubmit}
                value={newField.fieldValue}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Color</Form.Label>
              <Form.Control
                type="text"
                spellCheck={true}
                name="color"
                onChange={handleEdit}
                onBlur={handleSubmit}
                value={newField.color}
              />
            </Form.Group>
          </Col>
          <Col></Col>
          <Col className="align-self-center"></Col>
        </Row>
      </>
    );
  };

  return (
    <Form key={item}>
      {addedTag.tagNumber && (
        <Alert variant="success">
          Added Tag #{addedTag.tagNumber} {addedTag.servicePart}{" "}
        </Alert>
      )}

      {editedTag.tagNumber && (
        <Alert variant="success">
          Edited Tag #{editedTag.tagNumber} {editedTag.servicePart}{" "}
        </Alert>
      )}

      {newMachine.name && (
        <Alert variant="info">Added Machine {newMachine.name} </Alert>
      )}

      <Row className="g-2 mb-3">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Site</Form.Label>
            <Form.Select
              placeholder="Site"
              value={formData.site}
              name="site"
              onChange={handleFieldChange}
            >
              <option></option>
              {sites.length > 0 &&
                sites.map((e) => {
                  return <option value={e._id}>{e.name}</option>;
                })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Machine</Form.Label>
            <Form.Select
              placeholder="Machine"
              value={formData.machine}
              name="machine"
              onChange={handleFieldChange}
            >
              <option></option>
              <option value={null}>New Machine</option>
              {machines.length > 0 &&
                machines.map((e) => {
                  return <option value={e._id}>{e.name}</option>;
                })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      {formData.machine === "New Machine" && (
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Machine Name</Form.Label>
              <Form.Control
                type="text"
                spellCheck={true}
                name="name"
                rows={3}
                onChange={handleMachineChange}
                value={machineData.name}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>LoTo</Form.Label>
              <Form.Control
                type="text"
                name="loto"
                onChange={handleMachineChange}
                value={machineData.loto}
              />
            </Form.Group>
          </Col>
          <Col className="align-self-end">
            <Form.Group className="mb-3">
              <Button
                type="submit"
                disabled={
                  !formData.site || !machineData.name || !machineData.loto
                }
                onClick={handleNewMachine}
              >
                Add Machine
              </Button>
            </Form.Group>
          </Col>
        </Row>
      )}

      {/* Service Part and Task */}
      <Row className="g-2 mb-3">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Service Part</Form.Label>
            <Form.Control
              type="text"
              spellCheck={true}
              name="servicePart"
              onChange={handleFieldChange}
              value={formData.servicePart}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Service Task</Form.Label>
            <Form.Control
              type="text"
              spellCheck={true}
              name="serviceTask"
              onChange={handleFieldChange}
              value={formData.serviceTask}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Tag number & status */}
      <Row className="g-2 mb-3">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Tag Number</Form.Label>
            <Form.Control
              type="number"
              name="tagNumber"
              onChange={handleFieldChange}
              value={formData.tagNumber}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Tag Status</Form.Label>
            <Form.Check
              type="switch"
              checked={formData.active}
              name="active"
              label="Active"
              onChange={handleSwitch}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Empty row for spacing */}
      <Row className="g-2 py-2 mb-3 border-bottom"></Row>

      {/* Custom Fields */}
      <Row className="g-2 mb-3 border-bottom">
        {formData.customFields.map((r, i) => (
          <ExistingCustomField
            key={i}
            index={i}
            eField={r}
            update={editCustomField}
            remove={deleteCustomField}
          />
        ))}
      </Row>

      <Row className="g-2 mb-3 border-bottom">
        <NewCustomField submit={addCustomField} />
      </Row>

      {/* Buttons */}
      <Form.Group className="d-flex justify-content-between mb-4 mt-4">
        {!isNew && (
          <Button variant="outline-secondary" onClick={previousTag}>
            Previous Tag
          </Button>
        )}

        {/* Add or Update Buttons */}
        {isNew ? (
          <Button
            className="mx-auto w-100"
            type="submit"
            disabled={
              !formData.site ||
              !formData.machine ||
              formData.machine === "New Machine" ||
              !formData.servicePart ||
              !formData.tagNumber
            }
            onClick={handleNewTag}
          >
            Add Record
          </Button>
        ) : (
          <Button
            className="mx-auto w-50"
            type="submit"
            disabled={
              !formData.site ||
              !formData.machine ||
              formData.machine === "New Machine" ||
              !formData.servicePart ||
              !formData.tagNumber
            }
            onClick={handleUpdateTag}
          >
            Update Record
          </Button>
        )}

        {!isNew && (
          <Button variant="outline-secondary" onClick={nextTag}>
            Next Tag
          </Button>
        )}
      </Form.Group>
    </Form>
  );
};

TagRecordForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default TagRecordForm;
