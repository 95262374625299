import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import {
  Button,
  CloseButton,
  Card,
  Row,
  Col,
  Modal,
  Alert,
} from "react-bootstrap";

import { SelectFilter, MultiSelectID } from "components/filters/filters";

import ActionButton from "components/common/ActionButton";
import { FilterSortTable as Table } from "components/table/tables";
import { getCurrentUser } from "context/Authorization";
import { useAPI2 } from "hooks/useFetch";

const CustomerServiceRecords = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const columns = useMemo(
    () => [
      {
        accessor: "site.name",
        Header: "Site",
      },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        Header: "Active",
        accessor: (d) => d.active.toString(),
        Filter: SelectFilter,
        filter: "includes",
      },
      {
        Header: "Change Status",
        Cell: (rowData) => {
          const item = rowData.row.original;
          return (
            <ActionButton
              icon="edit"
              title="Edit"
              variant="action"
              onClick={() => {
                setItem(item);
                setShowConfirmModal(true);
              }}
              className="p-0 me-2"
            />
          );
        },
      },
    ],
    []
  );

  const [result, , error, callAPI] = useAPI2();
  const [sites, , sError, fetchSites] = useAPI2();
  const [, , statusError, updateStatus] = useAPI2();
  const data = useMemo(() => result, [result]);
  const [update, setUpdate] = useState(false);

  // Confirmation modal
  const [item, setItem] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleStatusChange = () => {
    console.log(item);
    const data = {
      _id: item._id,
      active: !item.active,
    };

    if (item) {
      updateStatus({
        path: `/customer/machine`,
        method: "PATCH",
        payload: data,
      });
      setUpdate(true);
    }
  };

  // Prefilters
  const [siteList, setSiteList] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  // Fetch Sites
  useEffect(() => {
    const filters = JSON.stringify({ user: user.id });
    fetchSites({
      path: `/customer/sites?filters=${filters}`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Format the site list for the MultiSelectID
  useEffect(() => {
    const mysites = sites.map((s) => {
      return { site: s };
    });
    setSiteList(mysites);
  }, [sites]);

  //Fetch Data
  useEffect(() => {
    const filters = JSON.stringify({
      user: user.id,
      sites: selectedSites.map((s) => s.value),
    });

    if (update) {
      callAPI({
        path: `/customer/machines/?filters=${filters}`,
        method: "GET",
      });
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  // Verify authorization after Fetch
  useEffect(() => {
    if (result.error === "Unauthorized!") history.push("/login");
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  function ComfirmModal() {
    return (
      <>
        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
            <div className="position-relative z-index-1 light">
              <h4 className="mb-0 text-white" id="authentication-modal-label">
                Change Machine Status
              </h4>
            </div>
            <CloseButton
              variant="white"
              className="position-absolute end-0 me-2 mt-2 top-0"
              onClick={() => {
                setShowConfirmModal(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              {`Are you sure you want to mark this machine ${
                item.active ? "inactive" : "active"
              }`}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                handleStatusChange(item._id);
                setShowConfirmModal(false);
              }}
            >
              Confirm
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {statusError && <Alert variant="danger">{statusError}</Alert>}

      {showConfirmModal && <ComfirmModal item={item} />}

      <Card>
        <Card.Header className="text-center h3 border-bottom">
          Machines
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="mb-3 text-start">
            <h5 className="text-center">Select Site and Load Data</h5>
            <Col>
              <MultiSelectID
                data={siteList}
                column={"site"}
                value={"_id"}
                label={"name"}
                selected={selectedSites}
                setSelected={setSelectedSites}
              />
            </Col>
            <Col className="align-self-center">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUpdate(true);
                }}
              >
                Load Data
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Table columns={columns} data={data} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerServiceRecords;
