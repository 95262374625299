import React, { useEffect, useMemo } from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";

import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useRowSelect,
} from "react-table";

import { TextFilter as DefaultColumnFilter } from "components/filters/filters";

import Flex from "../common/Flex";

export function SelectTable({ columns, data, updateSelected }) {
  // Use the state and functions returned from useTable to build your UI

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  // Pass back the selected rows
  useEffect(() => {
    const selectedRowIds = selectedFlatRows.map((r) => r.original._id);
    updateSelected(selectedRowIds);
  }, [selectedFlatRows]); // eslint-disable-line react-hooks/exhaustive-deps

  // Render the UI for your table
  return (
    <div className="table-responsive">
      <table
        className="table table-striped table-bordered table-hover fs--1 mb-0 overflow-hidden"
        {...getTableProps()}
      >
        <thead className="bg-200 text-900">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Table Footer */}
      <Flex className="align-items-center justify-content-between mt-3">
        {/* Page n of N & Rows per Page*/}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
          </p>

          {/* Rows per Page */}
          <p className="mb-0 mx-2">Rows per page:</p>
          <Form.Select
            size="sm"
            className="w-auto"
            onChange={(e) => setPageSize(e.target.value)}
            defaultValue={pageSize}
          >
            {[5, 10, 20, 30, 40, 50].map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Flex>

        {/* Jump to page */}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0 mx-2">Go to page:</p>
          <Form.Control
            size="sm"
            className="w-25"
            type="number"
            name="sequenceNumber"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </Flex>

        {/* Nav Buttons */}
        <Flex alignItems="center" className="fs--1">
          <ButtonGroup size="sm">
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              First
            </Button>
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              Next
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            >
              Last
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </div>
  );
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (ed || sd) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values[id]);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}
dateBetweenFilterFn.autoRemove = (val) => !val;

export function FilterSortTable({ columns, data }) {
  const filterTypes = useMemo(
    () => ({
      // Or, override the default text filter to use "startWith"
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableBodyProps,
    headerGroups,
    flatRows,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableMultiSort: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered table-hover fs--1 mb-0 overflow-hidden">
        <thead className="bg-200 text-900">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="mb-1">
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div className="mb-1">
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell", { editable: false })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr
              style={{
                borderTop: "2px solid grey",
              }}
              {...group.getFooterGroupProps()}
            >
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <br />

      {/* Table Footer */}
      <Flex className="align-items-center justify-content-between mt-3">
        {/* Page n of N & Rows per Page*/}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
          </p>

          {/* Rows per Page */}
          <p className="mb-0 mx-2">Rows per page:</p>
          <Form.Select
            size="sm"
            className="w-auto"
            onChange={(e) => setPageSize(e.target.value)}
            defaultValue={pageSize}
          >
            {[10, 20, 30, 40, 50].map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Flex>

        {/* Jump to page */}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0 mx-2">Go to page:</p>
          <Form.Control
            size="sm"
            className="w-25"
            type="number"
            name="sequenceNumber"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </Flex>

        {/* Nav Buttons */}
        <Flex alignItems="center" className="fs--1">
          <ButtonGroup size="sm">
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              First
            </Button>
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              Next
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            >
              Last
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </div>
  );
}

export function FilterSortDLTable({ columns, data, setFilteredRows }) {
  const filterTypes = useMemo(
    () => ({
      // Or, override the default text filter to use "startWith"
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableBodyProps,
    headerGroups,
    flatRows,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableMultiSort: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // Pass back the filtered rows
  useEffect(() => {
    setFilteredRows(flatRows.map(r => r.original))
  }, [flatRows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered table-hover fs--1 mb-0 overflow-hidden">
        <thead className="bg-200 text-900">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="mb-1">
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div className="mb-1">
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell", { editable: false })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr
              style={{
                borderTop: "2px solid grey",
              }}
              {...group.getFooterGroupProps()}
            >
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <br />

      {/* Table Footer */}
      <Flex className="align-items-center justify-content-between mt-3">
        {/* Page n of N & Rows per Page*/}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
          </p>

          {/* Rows per Page */}
          <p className="mb-0 mx-2">Rows per page:</p>
          <Form.Select
            size="sm"
            className="w-auto"
            onChange={(e) => setPageSize(e.target.value)}
            defaultValue={pageSize}
          >
            {[10, 20, 30, 40, 50].map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Flex>

        {/* Jump to page */}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0 mx-2">Go to page:</p>
          <Form.Control
            size="sm"
            className="w-25"
            type="number"
            name="sequenceNumber"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </Flex>

        {/* Nav Buttons */}
        <Flex alignItems="center" className="fs--1">
          <ButtonGroup size="sm">
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              First
            </Button>
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              Next
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            >
              Last
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </div>
  );
}

export function FilterSortSelectTable({ columns, data, updateSelected }) {
  const filterTypes = useMemo(
    () => ({
      // Or, override the default text filter to use "startWith"
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableMultiSort: true,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  // Pass back the selected rows
  useEffect(() => {
    const selectedRowIds = selectedFlatRows.map((r) => r.original._id);
    updateSelected(selectedRowIds);
  }, [selectedFlatRows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="table-responsive">
      <table
        className="table table-striped table-bordered table-hover fs--1 mb-0 overflow-hidden"
        {...getTableProps()}
      >
        <thead className="bg-200 text-900">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="mb-1">
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div className="mb-1">
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell", { editable: false })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr
              style={{
                borderTop: "2px solid grey",
              }}
              {...group.getFooterGroupProps()}
            >
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <br />

      {/* Table Footer */}
      <Flex className="align-items-center justify-content-between mt-3">
        {/* Page n of N & Rows per Page*/}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
          </p>

          {/* Rows per Page */}
          <p className="mb-0 mx-2">Rows per page:</p>
          <Form.Select
            size="sm"
            className="w-auto"
            onChange={(e) => setPageSize(e.target.value)}
            defaultValue={pageSize}
          >
            {[10, 20, 30, 40, 50].map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Flex>

        {/* Jump to page */}
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0 mx-2">Go to page:</p>
          <Form.Control
            size="sm"
            className="w-25"
            type="number"
            name="sequenceNumber"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </Flex>

        {/* Nav Buttons */}
        <Flex alignItems="center" className="fs--1">
          <ButtonGroup size="sm">
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              First
            </Button>
            <Button
              size="sm"
              variant={
                canPreviousPage ? "outline-primary" : "outline-secondary"
              }
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              Next
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? "outline-primary" : "outline-secondary"}
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            >
              Last
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </div>
  );
}
