import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Card, Button, Form, Row, Col, Alert } from "react-bootstrap";
import Section from "components/common/Section";
import { useAPI2 } from "hooks/useFetch";
import logo from "assets/img/3tm-logo-white.png";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AccountSetupForm = () => {
  const query = useQuery();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [user, loading, error, callVerify] = useAPI2();
  const [, , uError, callUpdate] = useAPI2();
  const [retries, setRetries] = useState(0);

  const verifyEmail = async () => {
    console.log("verifying token");
    console.log("retries: ", retries);
    setRetries(retries + 1);

    const data = {
      verificationToken: query.get("token"),
      email: query.get("email"),
    };

    console.log(data);

    if (!data.email || !data.verificationToken) {
      console.log("missing query params");
      history.push("/login");
    }
    callVerify({
      path: "/auth/verify-email",
      method: "POST",
      payload: data,
    });
  };

  // Call verifyEmail
  useEffect(() => {
    if (!loading && !user.username && retries < 3) {
      verifyEmail();
    }
  });

  // Set Password
  const onSubmit = (data) => {
    const payload = {
      password: data.password,
      token: user.token,
      email: user.email,
    }

    callUpdate({
      path: "/auth/reset-password",
      method: "POST",
      payload: payload,
    });

    history.push("/login");
  };

  // redirect to login if there is an error
  useEffect(() => {
    if (error) {
      history.push("/login");
    }
    
  }, [error, history]);

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          {error && <Alert variant="danger">{error}</Alert>}
          {uError && <Alert variant="danger">{error}</Alert>}
          <Card>
          <Card.Title className="p-4" style={{ backgroundColor: "#7a96b3" }}>
            <img
              className="d-block mx-auto my-4"
              src={logo}
              alt="sent"
              width={100}
            />
          </Card.Title>
            <Card.Body className="px-4 pt-4">
              <h1 className="text-center">Welcome!</h1>
              <h3 className="text-center">{user?.username}</h3>
              <h5 className="text-center mb-6">{user?.email}</h5>
              <h5 className="mb-3">Set your password to continue</h5>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* Password */}
                <Col className="mb-3">
                  <Form.Group as={Col} className="mb-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Password is required",
                        },
                        minLength: {
                          value: 10,
                          message: "Password must have min length of 10",
                        },
                      })}
                    />

                    <div className="text-sm text-danger">{errors?.password?.message}</div>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      {...register("confirm", {
                        required: {
                          value: true,
                          message: "Confirm password is required",
                        },
                        validate: () => getValues("password") === getValues("confirm")
                      })}
                    />
                    <div className="text-sm text-danger">{errors?.confirm?.message}</div>
                    {errors.confirm && errors.confirm.type === "validate" && (
                      <div className="text-danger">
                        Confirm Password must match Password
                      </div>)}
                  </Form.Group>
                </Col>

                <Form.Group className="my-4">
                  <Button
                    className="w-100"
                    type="submit"
                  >
                    Continue
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AccountSetupForm;
