import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import {
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useAPI2 } from "hooks/useFetch";

const SiteRecordForm = ({ item, handleForm }) => {
  // State
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    address: "",
    contactPerson: "",
    contactNumber: "",
    contactEmail: "",
    travelTime: 0,
    workplaceExam: true,
    active: true,
  });

  const [companies, , , callAPI] = useAPI2();

  useEffect(() => {
    callAPI({
      path: `/companies`,
      method: "GET",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize Form
  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id,
        name: item.name,
        company: item.company._id,
        address: item.address,
        contactPerson: item.contactPerson,
        contactNumber: item.contactNumber,
        contactEmail: item.contactEmail,
        travelTime: item.travelTime,
        workplaceExam: item.workplaceExam,
        active: item.active,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    handleForm(formData);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Company</Form.Label>
            <Form.Select
              placeholder="Company"
              value={formData.company}
              name="company"
              onChange={handleFieldChange}
            >
              <option></option>
              {companies.length > 0 &&
                companies.map((e) => {
                  return <option value={e._id}>{e.name}</option>;
                })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Site Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={handleFieldChange}
              value={formData.name}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              onChange={handleFieldChange}
              value={formData.address}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Contact Person</Form.Label>
            <Form.Control
              type="text"
              name="contactPerson"
              onChange={handleFieldChange}
              value={formData.contactPerson}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              name="contactNumber"
              onChange={handleFieldChange}
              value={formData.contactNumber}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Contact Email</Form.Label>
            <Form.Control
              type="text"
              name="contactEmail"
              onChange={handleFieldChange}
              value={formData.contactEmail}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2 mb-3">
      <Col>
          <Form.Group className="mb-3">
            <Form.Label>Travel Time</Form.Label>
            <Form.Control
              type="number"
              name="travelTime"
              onChange={handleFieldChange}
              value={formData.travelTime}
            />
          </Form.Group>
        </Col>
        <Col>
        <Form.Group className="mb-3">
          <Form.Label>Workplace Exam</Form.Label>
          <Form.Check
            type="switch"
            checked={formData.workplaceExam}
            label="Required"
            name="workplaceExam"
            onChange={handleSwitch}
          />
        </Form.Group>
        </Col>
        <Col>
        <Form.Group className="mb-3">
          <Form.Label>Site Status</Form.Label>
          <Form.Check
            type="switch"
            checked={formData.active}
            name="active"
            label="Active"
            onChange={handleSwitch}
          />
        </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.name ||
            !formData.address ||
            !formData.contactPerson ||
            !formData.contactNumber ||
            !formData.contactEmail
          }
          onClick={handleSubmit}
        >
          {item ? "Update Record" : "Add Record"}
        </Button>
      </Form.Group>
    </Form>
  );
};

SiteRecordForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default SiteRecordForm;
